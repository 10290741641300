<template>
    <div v-if="result" class="advice-result-card">
        <div v-if="image || fallbackImage" class="advice-result-card__image">
            <img :src="image || fallbackImage" alt="" :loading="loading">
        </div>
        <div class="advice-result-card__content">
            <h3 class="advice-result-card__title">{{ title }}</h3>
            <div class="advice-result-card__text" v-html="text" /> <!-- eslint-disable-line vue/no-v-html -->
            <navlink
                v-if="linkBlock"
                :type="mapType(linkBlock.__typename)"
                :target="mapTarget(linkBlock.targetBlank)"
                :url="mapURI(linkBlock)"
                class="btn btn--chevron"
            >
                {{ linkBlock.title }}
            </navlink>
        </div>
    </div>
</template>

<script>
'use strict';

import { get } from 'lodash';

import navigation from '#/mixins/navigation';
import loading from '#/mixins/loading';
import Navlink from '~/_patterns/atoms/navlink/navlink.vue';

export default {
    components: {
        Navlink
    },
    mixins: [
        navigation,
        loading
    ],
    props: {
        result: {
            type: Object,
            default() {
                return {};
            }
        }
    },
    computed: {
        image() {
            return get(this.result, 'image[0].url', '');
        },
        fallbackImage() {
            return get(this.result, 'aanbiederCategory[0].image[0].url', '');
        },
        title() {
            return get(this.result, 'title', '');
        },
        text() {
            return get(this.result, 'text', '');
        },
        linkBlock() {
            return get(this.result, 'linkBlock[0]', '');
        }
    }
};
</script>
