import { isEmpty, unionBy } from 'lodash';

import query from '#/graphql';
import getEntry from '#/graphql/queries/getEntry.graphql';

export const SECTION_HANDLE = 'advicePages';

const state = {
        all: []
    },

    // getters
    getters = {
        all: myState => myState.all,
        getBySlug(myState) {
            return slug => myState.all.filter((item) => {
                return item.slug === slug;
            });
        },
        getByCustomSlug(myState) {
            return slug => myState.all.filter((item) => {
                return item.customSlug === slug;
            });
        }
    },

    // actions
    actions = {
        getBySlug({ dispatch, getters: myGetters }, { slug }) {
            const page = myGetters.getBySlug(slug)[0];

            if (page) {
                return page;
            }

            return dispatch('fetchBySlug', { slug });
        },
        getByCustomSlug({ dispatch, getters: myGetters }, { slug }) {
            const page = myGetters.getByCustomSlug(slug)[0];

            if (page) {
                return page;
            }

            return dispatch('fetchByCustomSlug', { slug });
        },
        async fetchBySlug({ commit, rootGetters }, { slug }) {
            const result = await query.call(this, getEntry, { section: SECTION_HANDLE, slug }),
                entry = result.data.entry;

            if (!isEmpty(entry)) {
                commit('setPage', entry);
            }

            return entry;
        },
        async fetchByCustomSlug({ commit, rootGetters }, { slug }) {
            const result = await query.call(this, getEntry, { section: SECTION_HANDLE, customSlug: slug }),
                entry = result.data.entry;

            if (!isEmpty(entry)) {
                commit('setPage', entry);
            }

            return entry;
        }
    },

    // mutations
    mutations = {
        setPage(myState, page) {
            myState.all = unionBy(myState.all, [page], 'id');
        },
        clearCache(myState) {
            myState.all = [];
        }
    };

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
