<template>
    <div>
        <div class="container">
            <duurzame-wegwijzer v-if="next" />
            <advice-overview v-if="!next" @404="throw_404" />
        </div>

        <site-footer />
    </div>
</template>

<script>
import { mapActions } from 'vuex';

import SiteFooter from '~/_patterns/organisms/site-footer/site-footer.vue';
import AdviceOverview from '~/_patterns/organisms/advice-overview/advice-overview.vue';
import DuurzameWegwijzer, { matrix } from '~/_patterns/organisms/duurzame-wegwijzer/duurzame-wegwijzer.vue';
import DuurzameWegwijzerMixin from '~/_patterns/organisms/duurzame-wegwijzer/duurzame-wegwijzer-mixin';

export default {
    components: {
        DuurzameWegwijzer,
        AdviceOverview,
        SiteFooter
    },
    mixins: [
        DuurzameWegwijzerMixin
    ],
    data() {
        return {
            matrix
        };
    },
    async created() {
        if (!this.next) {
            const page = await this.getByCustomSlug({ slug: this.params.slug });

            if (page === null) {
                this.throw_404();
            }
        }
    },
    methods: {
        throw_404() {
            this.$router.replace({ name: '404' });
        },
        ...mapActions('advicePages', {
            getByCustomSlug: 'getByCustomSlug'
        })
    }
};
</script>
