
// initial state
const myState = {
        active: true
    },

    // getters
    getters = {},

    // actions
    actions = {
        activate({ commit }) {
            commit('setActive', true);
        },
        deactivate({ commit }) {
            commit('setActive', false);
        }
    },

    // mutations
    mutations = {
        setActive(state, active) {
            state.active = active;
            if (active) {
                document.getElementById('body').classList.add('scrollfixed');
            } else {
                document.getElementById('body').classList.remove('scrollfixed');
            }
        }
    };

export default {
    namespaced: true,
    state: myState,
    getters,
    actions,
    mutations
};
