<template>
    <div :class="`hero hero--left-align background--bleed background--${content.backgroundColor.value} section`">
        <div class="component component--narrow component--left" v-html="content.html" /> <!-- eslint-disable-line vue/no-v-html -->
    </div>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    }
};
</script>
