<template>
    <faq :rows="content.faq" class="background background--color-lightest section" />
</template>

<script>
import Faq from '~/_patterns/molecules/faq/faq.vue';

export default {
    components: {
        Faq
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {
                    faq: []
                };
            }
        }
    }
};
</script>
