import axios from 'axios';
import { trim } from 'lodash';

export const HOME_URI = '__home__';
export const getUriFromPath = path => trim(path, '/');

// initial state
const myState = {
        all: []
    },

    // getters
    myGetters = {
        all: state => state.all,
        getBySlug(state) {
            return slug => state.all.filter((item) => {
                const path = `/${item.slug}`;
                return path === slug.trim() ? item : false;
            });
        }
    },

    // actions
    actions = {
        getContentPages({ getters, commit }, { force = false }) {
            if (!getters.all || force) {
                axios.get('/api/contentPages')
                    .then(({ data }) => {
                        commit('setContentPages', data.data);
                    });
            }
        },
        getContentPage({ getters, commit }, slug) {
            const trimmed = slug.trim();

            if (!getters.getBySlug(trimmed)[0]) {
                return axios.get(`/api/contentPages/${trimmed}`)
                    .then(({ data }) => {
                        commit('setContentPage', data);
                    });
            }
        }
    },

    // mutations
    mutations = {
        setContentPages(state, contentPages) {
            state.all = contentPages;
        },
        setContentPage(state, contentPage) {
            state.all = [
                ...state.all,
                contentPage
            ];
        }
    };

export default {
    namespaced: true,
    state: myState,
    getters: myGetters,
    actions,
    mutations
};
