<template>
    <div>
        <Matrix slug="home" />
        <SiteFooter />
    </div>
</template>

<script>
import Matrix from '../../../_patterns/organisms/matrix/matrix.vue';
import SiteFooter from '../../../_patterns/organisms/site-footer/site-footer.vue';

export default {
    components: {
        Matrix,
        SiteFooter
    }
};
</script>
