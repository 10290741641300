<template>
    <header class="advice-overview-header background--blue background--bleed">
        <div class="advice-overview-header__text">
            <div class="advice-overview-header__breadcrumb">
                <breadcrumb>
                    <router-link :to="{ name: 'duurzame-wegwijzer' }" class="dw-breadcrumb__link">Start opnieuw</router-link>
                </breadcrumb>
            </div>
            <h1><img src="/img/duurzame-wegwijzer/logo-small.png" alt="" :loading="loading"> {{ resultCount || '…' }} {{ resultCountLabel }} voor jou</h1>
            <div class="advice-overview-header__wysiwyg">
                <span v-html="text" /> <!-- eslint-disable-line vue/no-v-html -->
                <span>Heb je nog vragen? <a href="mailto:nieuwamsterdamsklimaat@amsterdam.nl">Mail ons</a> dan en we helpen je graag.</span>
            </div>
        </div>
        <img v-if="!image" class="advice-overview-header__image" src="/img/duurzame-wegwijzer/logo-big.png" alt="Duurzame wegwijzer" :loading="loading">
        <img v-else class="advice-overview-header__image" :src="image" alt="" :loading="loading">
    </header>
</template>

<script>
'use strict';

import Breadcrumb from '../../../_patterns/atoms/dw-breadcrumb/dw-breadcrumb.vue';
import loading from '#/mixins/loading';

export default {
    components: {
        Breadcrumb
    },
    mixins: [
        loading
    ],
    props: {
        resultCount: {
            type: Number,
            default: 0,
            required: true
        },

        image: {
            type: String,
            default: '',
            required: false
        },
        text: {
            type: String,
            default: '',
            required: false
        }
    },
    computed: {
        pageUrl() {
            return window.location.href;
        },
        resultCountLabel() {
            return this.resultCount === 1 ? 'resultaat' : 'resultaten';
        }
    }
};
</script>
