import { print } from 'graphql/language/printer';
import axios from 'axios';

import config from '#/config/config';

/**
 * This helper function will query a Craft CMS backend, automatically including a Preview Token and SiteID.
 *
 * @TODO Refactor this function to suit your needs if you're not using Craft but want some
 * nice defaults in a similar manner
 *
 * @param any query
 *  A query string or a parsed graphql-tag AST
 * @param {*} variables
 */
export default async function executeCraftQuery(query, variables = {}) {
    const headers = {
            Authorization: config.api.token
        },
        stringifiedQuery = getStringifiedQuery(query);

    // Get fresh data from an API using axios
    let results;
    try {
        results = await axios.post(config.api.endpoint, {
            query: stringifiedQuery,
            variables
        }, { headers });
    } catch (e) {
        console.error(e); // eslint-disable-line no-console

        throw e;
    }

    return results.data;
}

export function getStringifiedQuery(query) {
    if (typeof query === 'string') {
        return query;
    } else {
        return print(query);
    }
}
