<template>
    <div v-if="showHero" class="hero" :class="classSelectors">
        <div v-if="content.photoBorder" class="hero__photo-breaker" />

        <div class="section" :class="containerClasses">
            <div v-if="logo" ref="logo" v-inview:enter="setLogoVisible" v-inview:leave="setLogoInvisible" class="hero__logo">
                <site-logo-animated v-if="animated" :show-text="false" size="xl" />
                <site-logo v-else :show-text="false" size="xl" />
            </div>

            <div class="hero__content">
                <h2 v-if="content.heroTitle" class="hero__title">{{ content.heroTitle }}</h2>

                <div v-if="content.image" class="hero__image">
                    <img :src="content.image.url" alt="" :loading="loading">
                </div>

                <p v-if="content.heroSubTitle" class="hero__lead lead">{{ content.heroSubTitle }}</p>

                <call-to-action v-if="content.callToActionText && content.callToActionUrl" :button-theme="buttonTheme" :url="content.callToActionUrl">
                    {{ content.callToActionText }}
                </call-to-action>
            </div>
        </div>
    </div>
    <div v-else class="hero" :class="classSelectors">
        <!-- SKELETON -->
        <content-placeholders>
            <div class="section" :class="containerClasses">
                <div class="hero__logo">
                    <content-placeholders-img />
                </div>

                <div class="hero__content">
                    <content-placeholders-heading />
                    <content-placeholders-text :lines="8" />
                </div>
            </div>
        </content-placeholders>
    </div>
</template>

<script>

/**
     * heroTitle
        heroTheme.value
        heroSubTitle
        callToActionText
        callToActionUrl
        buttonColor.value
        photoBorder
        image
     */

'use strict';

import Vue from 'vue';
import { mapActions } from 'vuex';
import InView from 'in-view';
import SiteLogo from '../../../_patterns/atoms/site-logo/site-logo.vue';
import CallToAction from '../../../_patterns/atoms/call-to-action/call-to-action.vue';
import SiteLogoAnimated from '../../atoms/site-logo-animated/site-logo-animated.vue';
import loading from '#/mixins/loading';

export default {
    components: {
        SiteLogoAnimated,
        CallToAction,
        SiteLogo
    },
    mixins: [
        loading
    ],
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        },
        logo: {
            type: Boolean,
            default: false
        },
        columns: {
            type: Boolean,
            default: false
        },
        animated: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        classSelectors() {
            let classes = this.content.photoBorder ? 'hero--photobreaker' : '';
            classes = this.columns ? classes.concat(' ', 'hero--columns') : classes.concat(' ', 'component--narrow');
            return classes;
        },
        containerClasses() {
            return this.content.heroTheme && this.content.heroTheme.value ? 'background background--bleed background--' + this.content.heroTheme.value : '';
        },
        showHero() {
            return this.content.heroTitle || this.content.heroSubTitle || this.content.image;
        },
        buttonTheme() {
            if (this.content.heroTheme && this.content.heroTheme.value === 'blue') {
                // Has blue theme
                return this.content.buttonColor && this.content.buttonColor.value !== 'primary' ? this.content.buttonColor.value : '';
            } else if (this.content.heroTheme && this.content.heroTheme.value === 'white') {
                // Has white theme
                return this.content.buttonColor && this.content.buttonColor.value !== 'secondary' ? this.content.buttonColor.value : '';
            } else {
                return '';
            }
        }
    },
    updated() {
        this.checkLogoVisibility();
    },
    mounted() {
        this.checkLogoVisibility();
    },
    destroyed() {
        this.setLogoInvisible();
    },
    methods: {
        checkLogoVisibility() {
            if (this.logo) {
                Vue.nextTick(() => {
                    if (this.$refs.logo) {
                        if (InView.is(this.$refs.logo)) {
                            this.setLogoVisible();
                        }
                    }
                });
            }
        },
        ...mapActions('heroHome', {
            setLogoVisible: 'setLogoVisible',
            setLogoInvisible: 'setLogoInvisible'
        })
    }
};
</script>
