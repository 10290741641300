<template>
    <ec-form :handle="handle" />
</template>

<script>
import { get } from 'lodash';
import EcForm from '~/_patterns/atoms/ec-form/ec-form.vue';

export default {
    components: {
        EcForm
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {
                    handle: null
                };
            }
        }
    },
    computed: {
        handle() {
            return get(this, 'content.handle', null);
        }
    }
};
</script>
