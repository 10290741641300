<template>
    <div :class="`content_page_hero content_page_hero_index content_hero_style_${content.contentHeroBackgroundColor.value}`">
        <div class="section section--fat-top">
            <div class="content_hero__grid">
                <div class="content_hero__cell">
                    <h2 v-if="content.contentPageIndexHeroTitle">{{ content.contentPageIndexHeroTitle }}</h2>
                    <div v-if="content.contentPageIndexHeroSubTitle" class="content-hero-subheader">
                        <p>{{ content.contentPageIndexHeroSubTitle }}</p>
                    </div>
                </div>
            </div>

            <div class="content-hero-index-container">
                <component :is="content.contentPageIndexHeroSource.label" v-if="content.contentPageIndexHeroSource.label" :paginated="true" :show-link-to-more-updates="false" />
            </div>
        </div>
    </div>
</template>

<script>
import Updates from '../../../_patterns/organisms/updates/updates.vue';

export default {
    components: {
        Updates
    },
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    }
};
</script>
