<template>
    <div>
        <div class="container">
            <div class="row">
                <hero :content="hero" :logo="true" :animated="false" :columns="true" class="hero--home component" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Hero from '../../../_patterns/molecules/hero/hero.vue';

export default {
    components: {
        Hero
    },
    computed: {
        ...mapGetters('heroHome', {
            hero: 'hero'
        })
    },
    created() {
        this.getHero();
    },
    methods: {
        ...mapActions('heroHome', {
            getHero: 'getHero'
        })
    }
};
</script>
