<template>
    <div>
        <label class="form-label">{{ field.label }}&nbsp;</label>
        <div v-if="field.instructions" class="form-field-instructions">{{ field.instructions }}</div>
        <div>
            <input
                :id="field.handle"
                :name="field.handle"
                type="file"
                :placeholder="field.placeholder"
                :value="field.value"
                @change="change"
            >
        </div>
        <label :for="field.handle" class="btn updates__more-button">Bestand kiezen</label>
        <div v-if="uploadedFile" class="uploaded-file-label">Gekozen bestand: {{ uploadedFile.name }}</div>
    </div>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            uploadedFile: false
        };
    },
    methods: {
        change(event) {
            if (event.srcElement.files && event.srcElement.files.length > 0) {
                this.uploadedFile = event.srcElement.files[0];
            } else {
                this.uploadedFile = false;
            }
        }
    }
};
</script>
