<template>
    <div>
        <div v-if="content" class="container">
            <div v-for="(matrix, key) in content.matrix" :key="key" class="row">
                <component :is="matrix.type" v-if="matrix.type" :content="matrix" class="component" />
            </div>

            <div class="row">
                <initiatives-call-to-action v-if="content.callToAction" :content="content.callToAction" class="component" />
            </div>
        </div>
        <div v-else-if="!content || !content.matrix || content.matrix.length <= 0" class="container">
            <skeleton />
        </div>

        <SiteFooter />
    </div>
</template>

<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import contentPageHero from '../../../_patterns/molecules/content-page-hero/content-page-hero.vue';
import contentPageIndexHero from '../../../_patterns/molecules/content-page-index-hero/content-page-index-hero.vue';
import contentPageContactHero from '../../../_patterns/molecules/content-page-contact-hero/content-page-contact-hero.vue';
import SiteFooter from '../../../_patterns/organisms/site-footer/site-footer.vue';
import GenericHtml from '../../../_patterns/organisms/generic-html/generic-html.vue';
import RepeaterBlock from '../../../_patterns/organisms/repeater-block/repeater-block.vue';
import LinkList from '../../../_patterns/organisms/link-list/link-list.vue';
import InitiativesCallToAction from '../../../_patterns/molecules/initiative-cta/initiative-cta.vue';
import dwIntro from '../../../_patterns/organisms/dw-intro-block/dw-intro-block.vue';
import FAQ from '~/_patterns/organisms/faq/faq';
import EcForm from '~/_patterns/molecules/ec-form/ec-form';
import Skeleton from '~/_patterns/molecules/skeleton/skeleton';

export default {
    components: {
        Skeleton,
        SiteFooter,
        contentPageHero,
        contentPageContactHero,
        contentPageIndexHero,
        InitiativesCallToAction,
        GenericHtml,
        RepeaterBlock,
        LinkList,
        dwIntro,
        faq: FAQ,
        energyChallengeEmailForm: EcForm
    },
    computed: {
        content() {
            const content = this.getBySlug(this.path)[0];

            if (typeof content === 'object' && content.title) {
                document.title = content.title;
                this.$router.options.setDocumentTitle(content.title);
            }

            return content;
        },
        ...mapGetters('contentPages', {
            getBySlug: 'getBySlug'
        }),
        ...mapState('route', {
            path: 'path'
        })
    },
    created() {
        this
            .getContentPage(this.path)
            .catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            });
    },
    methods: {
        ...mapActions('contentPages', {
            getContentPage: 'getContentPage'
        })
    }
};
</script>
