
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"flexibleInitiativesBlock"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"flexibleSection_initiativesBlock_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"title"},"name":{"kind":"Name","value":"titel"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"text"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"initiativesCollection"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"initiatives_initiatieven_Entry"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"image"},"name":{"kind":"Name","value":"imageContentPage"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"defaultImageAsset"},"directives":[]}]}},{"kind":"Field","alias":{"kind":"Name","value":"video"},"name":{"kind":"Name","value":"initiativeVideo"},"arguments":[],"directives":[]}]}}]}}]}}],"loc":{"start":0,"end":418}};
    doc.loc.source = {"body":"#import '#/graphql/fragments/defaultImageAsset.graphql'\n\nfragment flexibleInitiativesBlock on flexibleSection_initiativesBlock_BlockType {\n    title: titel\n    text\n    initiativesCollection {\n        ... on initiatives_initiatieven_Entry {\n            title\n            uri\n            image: imageContentPage {\n                ... defaultImageAsset\n            }\n            video: initiativeVideo\n        }\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require('#/graphql/fragments/defaultImageAsset.graphql').definitions));


      module.exports = doc;
    
