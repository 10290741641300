const env = process.env.APP_ENV || 'development',

    config = {
        dateFormat: 'DD/MM/YYYY',
        dateTimeFormat: 'DD/MM/YYYY H:i'
    },

    localizedConfigs = {
        production: require('./production').default,
        staging: require('./staging').default,
        development: require('./development').default
    },

    localizedConfig = localizedConfigs[env];

export default Object.assign(config, localizedConfig);
