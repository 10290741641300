export default {
    methods: {
        getLinkTarget(url) {
            const def = '_self';

            if (!window) {
                return def;
            }

            if (
                url.indexOf('http') === 0 &&
                !url.includes(window.location.hostname)
            ) {
                return '_blank';
            }

            return def;
        }
    }
};
