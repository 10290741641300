import axios from 'axios';

// initial state
const myState = {
        all: []
    },

    // getters
    getters = {},

    // actions
    actions = {
        getPlannings({ commit }) {
            if (!this.plannings) {
                axios.get('/api/planning').then((response) => {
                    this.plannings = response.data.data[0];
                    commit('setPlannings', response.data.data[0]);
                });
            }
        }
    },

    // mutations
    mutations = {
        setPlannings(state, plannings) {
            state.all = plannings;
        }
    };

export default {
    namespaced: true,
    state: myState,
    getters,
    actions,
    mutations
};
