import axios from 'axios';

// initial state
const myState = {
        all: []
    },

    // getters
    myGetters = {
        all: state => state.all,
        bySlug: state => slug => state.all.filter(hero => hero.slug === slug)
    },

    // actions
    actions = {
        getHeroes({ getters, commit }) {
            if (!getters.heroes) {
                axios
                    .get('/api/heroes')
                    .then(({ data }) => {
                        commit('setHeroes', data.data);
                    });
            }
        },
        getHero({ getters, commit }, slug) {
            if (!getters.heroes) {
                axios
                    .get(`/api/heroes/${slug}`)
                    .then(({ data }) => {
                        commit('setHero', data);
                    });
            }
        }
    },

    // mutations
    mutations = {
        setHeroes(state, heroes) {
            state.all = heroes;
        },
        setHero(state, hero) {
            state.all = [
                ...state.all,
                hero
            ];
        }
    };

export default {
    namespaced: true,
    state: myState,
    getters: myGetters,
    actions,
    mutations
};
