<template>
    <router-link v-if="type === INTERNAL" :to="url" :target="target" :rel="rel" :class="classes">
        <slot />
    </router-link>

    <a v-else :href="url" :target="target" :rel="rel" :class="classes">
        <slot />
    </a>
</template>

<script>
import { INTERNAL, EXTERNAL, TARGET_BLANK, TARGET_SELF } from '#/mixins/navigation';

export default {
    props: {
        type: {
            type: String,
            validator: value => [INTERNAL, EXTERNAL].includes(value),
            default: INTERNAL
        },
        url: {
            type: [String, Object],
            required: true
        },
        target: {
            type: String,
            default: TARGET_SELF
        },
        classes: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            INTERNAL,
            EXTERNAL
        };
    },
    computed: {
        rel() {
            if (this.target === TARGET_BLANK) {
                return 'noopener noreferrer';
            }

            return '';
        }
    }
};
</script>
