
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"linkBlock"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"linkBlock_MatrixField"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"linkBlock_linkInternal_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"title"},"name":{"kind":"Name","value":"titel"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"linkInternal"},"name":{"kind":"Name","value":"linkurl"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]}]}}]}},{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"linkBlock_linkExternal_BlockType"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"title"},"name":{"kind":"Name","value":"titel"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"linkExternal"},"name":{"kind":"Name","value":"linkurl"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"targetBlank"},"arguments":[],"directives":[]}]}}]}}],"loc":{"start":0,"end":335}};
    doc.loc.source = {"body":"fragment linkBlock on linkBlock_MatrixField {\n    __typename\n    ... on linkBlock_linkInternal_BlockType {\n        title: titel\n        linkInternal: linkurl {\n            title\n            uri\n        }\n    }\n    ... on linkBlock_linkExternal_BlockType {\n        title: titel\n        linkExternal: linkurl\n        targetBlank\n    }\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
