<template>
    <div>
        <slot />
        <div v-show="pagination.length > 1" class="pagination_navigation">
            <span v-if="pageNumber > 0" class="pagination-button pagination-button__prev" @click="prevPage" />
            <span
                v-for="page in pagination"
                :key="page"
                class="pagination-button"
                :class="{ active: page === (pageNumber)}"
                @click="goToPage(page)"
            >
                {{ page + 1 }}
            </span>
            <span v-if="pageNumber < lastPage" class="pagination-button pagination-button__next" @click="nextPage" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: {
            type: Array,
            required: false,
            default: () => { return []; }
        },
        itemsPerPage: {
            type: Number,
            default: 8,
            required: false
        }
    },
    data() {
        return {
            paginatedData: [],
            pageNumber: 0
        };
    },
    computed: {
        pageCount() {
            return Math.ceil(this.items.length / this.itemsPerPage);
        },
        lastPage() {
            return this.pageCount - 1;
        },
        pagination() {
            const paginationArr = [];

            [-3, -2, -1, 0, 1, 2, 3].forEach((index) => {
                if (this.inRange(index)) {
                    paginationArr.push(this.pageNumber + index);
                }
            });

            return paginationArr;
        }
    },
    created() {
        this.changePage();
    },
    methods: {
        goToPage(index) {
            this.pageNumber = index;
            this.changePage();
        },
        nextPage() {
            if (this.pageNumber < this.lastPage) {
                this.pageNumber++;
                this.changePage();
            }
        },
        prevPage() {
            if (this.pageNumber > 0) {
                this.pageNumber--;
                this.changePage();
            }
        },
        changePage() {
            const start = this.pageNumber * this.itemsPerPage,
                end = start + this.itemsPerPage;

            this.paginatedData = this.items.slice(start, end);

            this.$emit('paginate', this.paginatedData);
        },
        inRange(index) {
            return (this.pageNumber + index >= 0) && (this.pageNumber + index <= this.lastPage);
        }
    }
};
</script>
