import Vue from 'vue';
import Vuex from 'vuex';
import heroes from './modules/heroes';
import heroHome from './modules/hero-home';
import updates from './modules/updates';
import initiatives from './modules/initiatives';
import plannings from './modules/plannings';
import modal from './modules/modal';
import contentPages from './modules/content-pages';
import tools from './modules/tools';
import advicePages from './modules/advice-pages';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        heroes,
        heroHome,
        updates,
        initiatives,
        plannings,
        modal,
        contentPages,
        tools,
        advicePages
    }
});
