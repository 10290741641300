<template>
    <div class="advice-group">
        <h2>{{ content.title }}</h2>

        <advice-result-card
            v-for="(block, key) in content.adviseList"
            :key="key"
            :result="block"
        />
    </div>
</template>

<script>

import AdviceResultCard from '~/_patterns/molecules/advice-result-card/advice-result-card.vue';

export default {
    components: {
        AdviceResultCard
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {
                    titel: '',
                    adviseList: []
                };
            }
        }
    }
};
</script>
