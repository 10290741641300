<template>
    <save-results :path="params.slug" />
</template>

<script>
import { mapState } from 'vuex';
import SaveResults from '~/_patterns/organisms/save-results/save-results.vue';

export default {
    components: {
        SaveResults
    },
    props: {
        content: {
            type: Object,
            required: false,
            default: () => { return {}; }
        }
    },
    computed: {
        ...mapState('route', {
            params: 'params'
        })
    }
};
</script>
