<template>
    <div class="duurzame-wegwijzer component">
        <splash-loader v-if="splashVisible" />

        <breadcrumb>
            <router-link v-if="previousLink" :to="previousLink" class="dw-breadcrumb__link">Vorige vraag</router-link>
            <router-link v-else to="/wat-kan-jij-doen" class="dw-breadcrumb__link">Terug</router-link>
        </breadcrumb>
        <h2 class="duurzame-wegwijzer__title">{{ question }}</h2>

        <ul class="duurzame-wegwijzer__questions link-list">
            <li v-for="(v, link) in next" :key="link">
                <router-link :to="nextLink(link)" :event="NAVIGATE_EVENT" @click.native="onClickEvent($event, v, link)">
                    {{ v.answer }}
                    <span v-if="v.explain">*</span>
                </router-link>
            </li>
        </ul>

        <p v-if="explain" class="duurzame-wegwijzer__subtext duurzame-wegwijzer__subtext--asterix">
            Kijk <navlink url="https://maps.amsterdam.nl/monumenten/" :type="EXTERNAL" :target="TARGET_BLANK">hier</navlink> of je woning een monument is.
            Kijk <navlink url="https://maps.amsterdam.nl/cultuurhistorie/?LANG=nl/monumenten/" :type="EXTERNAL" :target="TARGET_BLANK">hier</navlink> of je woning in een beschermd stadsgezicht staat.
        </p>

        <p class="duurzame-wegwijzer__subtext">
            <span class="duurzame-wegwijzer__subtext__content">Kom je er niet uit? <a href="mailto:nieuwamsterdamsklimaat@amsterdam.nl">Mail ons</a> en we helpen je graag</span>
        </p>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { get, has } from 'lodash';

import Breadcrumb from '~/_patterns/atoms/dw-breadcrumb/dw-breadcrumb.vue';
import SplashLoader from '~/_patterns/atoms/splash-loader/splash-loader.vue';

import DuurzameWegwijzerMixin from '~/_patterns/organisms/duurzame-wegwijzer/duurzame-wegwijzer-mixin';
import Navlink from '~/_patterns/atoms/navlink/navlink';
import { EXTERNAL, TARGET_BLANK } from '#/mixins/navigation';

export const matrix = {
    '': {
        question: 'Wie ben jij?',
        paths: {
            'een-bewoner': {
                answer: 'Een bewoner',
                hasNext: true
            },
            bedrijf: {
                answer: 'Een bedrijf of groep bedrijven',
                hasNext: true
            },
            instelling: {
                answer: 'Een instelling voor onderwijs, cultuur, sport of zorg',
                hasNext: true
            },
            collectief: {
                answer: 'Een bewonerscollectief'
            }
        }
    },
    'een-bewoner': {
        question: 'Waar wil je mee aan de slag?',
        paths: {
            'duurzaam-wonen': {
                question: 'Hoe woon je?',
                answer: 'Duurzaam wonen',
                paths: {
                    'particulier-eigenaar': {
                        question: 'Wat voor woning heb je?',
                        answer: 'Particulier eigenaar',
                        paths: {
                            'geen-monument': {
                                answer: 'Bestaande bouw, geen monument'
                            },
                            monument: {
                                answer: 'Monument of woning in een beschermd stadsgezicht',
                                explain: true
                            },
                            'nog-bouwen': {
                                answer: 'Ik ga zelf bouwen'
                            }
                        }
                    },
                    vve: {
                        answer: 'Eigenaar in een VvE'
                    },
                    huurder: {
                        answer: 'Huurder'
                    }
                }
            },
            'schoon-vervoer': {
                answer: 'Schoon vervoer'
            },
            vergroenen: {
                answer: 'Huis, tuin of buurt vergroenen'
            },
            'circulair-leven': {
                answer: 'Circulair leven'
            }
        }
    },
    bedrijf: {
        question: 'Waar wil je mee aan de slag?',
        paths: {
            'pand-verduurzamen': {
                answer: 'Pand verduurzamen'
            },
            'bedrijfsvoering-verduurzamen': {
                answer: 'Bedrijfsvoering verduurzamen'
            }
        }
    },
    instelling: {
        question: 'Waar wil je mee aan de slag?',
        paths: {
            'pand-verduurzamen': {
                answer: 'Pand verduurzamen'
            },
            'bedrijfsvoering-verduurzamen': {
                answer: 'Bedrijfsvoering verduurzamen'
            }
        }
    }
};

const NAVIGATE_EVENT = 'custom-navigate',
    SPLASH_DELAY = 2000;

export default {
    components: {
        Navlink,
        Breadcrumb,
        SplashLoader
    },
    mixins: [
        DuurzameWegwijzerMixin
    ],
    data() {
        return {
            matrix,
            NAVIGATE_EVENT,
            EXTERNAL,
            TARGET_BLANK,
            splashVisible: false
        };
    },
    computed: {
        question() {
            return get(this.matrix, `${this.path}.question`);
        },
        explain() {
            if (this.next) {
                return Object
                    .values(this.next)
                    .some(v => v.explain);
            }

            return false;
        },
        previousLink() {
            if (this.params.slug) {
                const prevSlug = this.params.slug.split('/').slice(0, -1).join('/');

                return `${this.meta.root}/${prevSlug}`;
            }

            return null;
        },
        ...mapState('route', ['params', 'meta'])
    },
    mounted() {
        document.body.classList.add('background--grey');
    },
    destroyed() {
        document.body.classList.remove('background--grey');
    },
    methods: {
        shouldActivateSplash(node) {
            return !has(node, 'paths') && !has(node, 'hasNext');
        },
        onClickEvent(e, node, link) {
            const nextLink = this.nextLink(link).replace('/duurzame-wegwijzer/', ''),
                event = document.createEvent('Event');

            event.initEvent(NAVIGATE_EVENT, true, true);

            if (this.shouldActivateSplash(node)) {
                this.splashVisible = true;

                // preload the page during the preload.
                this.getByCustomSlug({ slug: nextLink });

                // continue the navigation by firing the NAVIGATE_EVENT
                setTimeout(() => {
                    e.target.dispatchEvent(event);
                }, SPLASH_DELAY);
                return;
            }

            // continue the navigation by firing the NAVIGATE_EVENT
            e.target.dispatchEvent(event);
        },
        nextLink(next) {
            if (!this.params.slug) {
                return `${this.meta.root}/${next}`;
            }

            return `${this.meta.root}/${this.params.slug}/${next}`;
        },
        ...mapActions('advicePages', {
            getByCustomSlug: 'getByCustomSlug'
        })
    }
};
</script>
