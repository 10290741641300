<template>
    <ModalPopup v-if="explanationVisible" class="initiatives-start animated fadeIn fast" @close="close">
        <template v-slot:header>
            <h4>Nieuw Amsterdams Klimaat op de kaart</h4>
        </template>

        <template v-slot:default>
            <p>
                <span class="initiatives-start__marker">
                    {{ initiativesCount }}
                </span>
                Op deze kaart zie je projecten van bewoners, bedrijven en instellingen voor een gezonde en duurzame
                stad. Van een VVE met zonnepanelen tot een groen waterstofcluster in de haven. De een is pas net
                begonnen en zet met hulp van dit platform de volgende stap, de ander is al bijna klaar.
                <br><br>Heb je zelf een duurzaam project? Voeg het toe aan de kaart van Nieuw Amsterdams Klimaat.
            </p>
        </template>

        <template v-slot:footer>
            <button class="btn btn--primary btn--no-mobile-icon initiatives-start__footer-button" @click="close">Verken de kaart</button>
        </template>
    </ModalPopup>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ModalPopup from '../../../_patterns/atoms/modal-popup/modal-popup.vue';

export default {
    components: {
        ModalPopup
    },
    computed: {
        ...mapGetters('initiatives', {
            explanationVisible: 'explanationVisible',
            initiativesCount: 'count'
        })
    },
    created() {
        this.getCount();
    },
    methods: {
        ...mapActions('initiatives', {
            close: 'hideExplanation',
            getCount: 'getCount'
        })
    }
};
</script>
