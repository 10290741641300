<template>
    <li class="nav-item">
        <router-link :to="route">
            <slot>{{ title }}</slot>
        </router-link>
    </li>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        route: {
            type: [String, Object],
            default: ''
        }
    }
};
</script>
