<template>
    <div>
        <div class="form__grid form__grid2 checkbox_single">
            <div class="form__cell">
                <input :id="id" :name="field.handle" type="checkbox">
                <label :for="id"><span v-html="field.label" /> <div class="checkbox-square" /></label> <!-- eslint-disable-line vue/no-v-html -->
                <div v-if="field.instructions" class="form-field-instructions">{{ field.instructions }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    computed: {
        id() {
            return 'checkbox-' + this.field.handle + '-' + this.field.value;
        }
    }
};
</script>
