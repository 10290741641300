<template>
    <div class="hero hero--grey background background--grey background--bleed">
        <div class="container map-preview">
            <div class="section">
                <div class="map-preview__border map-preview__border--top-left" />
                <div class="map-preview__border map-preview__border--top-right" />
                <div class="map-preview__border map-preview__border--bottom-left" />
                <div class="map-preview__border map-preview__border--bottom-right" />

                <div class="map-preview__header">
                    <h2>Al {{ initiativesCount }} projecten in de stad</h2>
                </div>

                <div class="map-preview__map">
                    <img class="map-preview__map__image" src="/img/initiatives-map.png" :loading="loading">

                    <div v-if="content.vimeoId" class="video-frame">
                        <div class="aspect-ratio">
                            <vimeo :id="content.vimeoId" />
                        </div>

                        <p>Projecten in de stad</p>
                    </div>
                </div>

                <router-link :to="{ name: 'Initiatieven' }">
                    <button class="btn updates__more-button">Wat doen Amsterdammers</button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Vimeo from '../../../_patterns/atoms/vimeo/vimeo.vue';
import loading from '#/mixins/loading';

export default {
    components: {
        Vimeo
    },
    mixins: [
        loading
    ],
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    },
    computed: {
        ...mapGetters('initiatives', {
            initiativesCount: 'count'
        })
    },
    created() {
        this.getCount();
    },
    methods: {
        ...mapActions('initiatives', {
            getCount: 'getCount'
        })
    }
};
</script>
