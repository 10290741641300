<template>
    <form ref="form" method="post" class="vue-form ec-form" @submit.prevent="submitForm">
        <div class="ec-form__form">
            <input
                v-model="email"
                type="email"
                name="email"
                placeholder="Emailadres"
                required
                autocomplete="email"
            >
            <button type="submit" class="btn btn--primary">Aanmelden</button>
        </div>
        <div v-if="hasErrors" class="errors">
            <template v-for="(field, fieldKey) in errors">
                <p v-for="(error, key) in field" :key="`${fieldKey}-${key}`">
                    {{ error }}
                </p>
            </template>
        </div>

        <div v-if="messages" class="messages">
            <p v-for="(message, key) in messages" :key="key">
                {{ message }}
            </p>
        </div>
    </form>
</template>

<script>
import qs from 'querystring';
import { get, isEmpty } from 'lodash';
import axios from 'axios';

export default {
    props: {
        handle: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            messages: [],
            errors: {},
            formData: {},

            email: null
        };
    },
    computed: {
        hasErrors() {
            return !isEmpty(this.errors);
        }
    },
    async created() {
        const response = await axios.get(`/freeform.json?handle=${this.handle}`);

        this.formData = get(response, 'data.1.formData', {});
    },
    methods: {
        async submitForm() {
            this.messages = [];
            this.errors = {};

            const response = await axios.post('/', qs.stringify({
                    emailadres: this.email,
                    ...this.formData
                }), {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Cache-Control': 'no-cache',
                        'X-Requested-With': 'XMLHttpRequest',
                        HTTP_X_REQUESTED_WITH: 'XMLHttpRequest'
                    }
                }),
                data = response.data;

            // Store fresh honeypot token to form data for next request
            if (data.honeypot) {
                this.formData[data.honeypot.name] = data.honeypot.hash;
            }

            if (response.status !== 200 || data.success !== true) {
                this.errors = get(data, 'errors', [{ general: 'Er ging iets fout' }]);
                return false;
            }

            window.location.href = '/energy-challenge-bedankt'; // TODO: Connect to return url field in freeform
            this.messages.push('Formulier succesvol verzonden!');
            this.email = null;
        }
    }
};
</script>
