<template>
    <div>
        <a v-if="url !== ''" :href="url">
            <button class="call-to-action btn " :class="buttonClass">
                <slot />
            </button>
        </a>
        <router-link v-else :to="route">
            <button class="call-to-action btn " :class="buttonClass">
                <slot />
            </button>
        </router-link>
    </div>
</template>

<script>
export default {
    props: {
        route: {
            default: '',
            type: String
        },
        url: {
            default: '',
            type: String
        },
        buttonTheme: {
            default: '',
            type: String
        }
    },
    computed: {
        buttonClass() {
            return !this.buttonTheme || this.buttonTheme === 'default' || this.buttonTheme === '' ? '' : 'btn--' + this.buttonTheme;
        }
    }
};
</script>
