<template>
    <div :class="'content_page_hero content_page_contact_hero content_hero_style_'+content.contentHeroBackgroundColor.value">
        <div class="section section--fat-top">
            <div class="content-hero-contact-container">
                <VueForm :form-handle="content.freeFormHandle" />
            </div>
        </div>
    </div>
</template>

<script>

'use strict';

import VueForm from '../../../_patterns/molecules/vue-form/vue-form.vue';

export default {
    components: {
        VueForm
    },
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    }
};
</script>
