import axios from 'axios';

// initial state
const myState = {
        hero: {},
        logoVisible: false
    },

    // getters
    myGetters = {
        hero(state) {
            return {
                ...state.hero,
                heroTheme: { value: 'white' },
                buttonColor: { value: 'primary' }
            };
        },
        onlyhero: state => state.hero,
        logoVisible: state => state.logoVisible
    },

    // actions
    actions = {
        getHero({ state, commit }) {
            if (Object.keys(state.hero).length === 0 && state.hero.constructor === Object) {
                axios
                    .get('/api/home-hero')
                    .then(({ data }) => {
                        commit('setHero', data);
                    });
            }
        },
        setLogoVisible({ commit }) {
            commit('setLogoVisibility', true);
        },
        setLogoInvisible({ commit }) {
            commit('setLogoVisibility', false);
        }
    },

    // mutations
    mutations = {
        setHero(state, hero) {
            state.hero = hero;
        },
        setLogoVisibility(state, visibility) {
            state.logoVisible = visibility;
        }
    };

export default {
    namespaced: true,
    state: myState,
    getters: myGetters,
    actions,
    mutations
};
