<template>
    <div class="hamburger-menu" :class="{ 'hamburger-menu--open': showNav}">
        <div class="hamburger-menu__icon" :class="{ 'hamburger-menu__icon--open': showNav}" @click="toggleNav" />
        <div v-if="showNav" class="hamburger-menu__nav" @click="toggleNav">
            <MainNav />
        </div>
    </div>
</template>

<script>

import MainNav from '../../../_patterns/molecules/main-nav/main-nav.vue';

export default {
    components: {
        MainNav
    },
    data() {
        return {
            showNav: false
        };
    },
    methods: {
        toggleNav() {
            this.showNav = !this.showNav;
            this.$emit('nav-open', this.showNav);
        // this.$store.dispatch( this.showNav ? 'modal/activate' : 'modal/deactivate');
        }
    }
};
</script>
