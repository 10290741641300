<template>
    <div :id="listId" class="link-list" :class="classSelectors">
        <div v-if="photoBorder" class="link-list__photo-breaker" />
        <div class="section" :class="`background background--${backgroundColor} background--bleed ${photoBorder}`">
            <h2 v-if="header" class="link-list__header" v-text="header" />

            <template v-if="links.length > 0">
                <template v-for="(link, key) in links">
                    <navlink :key="key" :url="mapLegacyUri(link.uri)" :target="mapTypeToTarget(mapLegacyType(link.type))" :type="mapLegacyType(link.type)">
                        {{ link.text }}
                    </navlink>
                </template>
            </template>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import { mapState } from 'vuex';

import Navlink from '~/_patterns/atoms/navlink/navlink.vue';
import navigation from '#/mixins/navigation';

export default {
    components: {
        Navlink
    },
    mixins: [
        navigation
    ],
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    },
    computed: {
        classSelectors() {
            return this.photoBorder ? 'link-list--photobreaker' : '';
        },
        photoBorder() {
            return get(this, 'content.photoBorder', false);
        },
        backgroundColor() {
            return get(this, 'content.heroTheme.value', get(this, 'content.backgroundColor.value', ''));
        },
        header() {
            return get(this, 'content.title', get(this, 'content.header', get(this, 'content.heroTitle')));
        },
        links() {
            return get(this, 'content.links', []);
        },
        listId() {
            return 'linked-list-' + this.params.slug;
        },
        ...mapState('route', {
            params: 'params'
        })
    }
};
</script>
