<template>
    <figure class="vimeo_container">
        <iframe
            :src="url"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            :loading="loading"
            :title="caption"
        />

        <figcaption v-if="caption">{{ caption }}</figcaption>
    </figure>
</template>

<script>
import loading from '#/mixins/loading';

export default {
    mixins: [
        loading
    ],
    props: {
        id: {
            type: [String, Number],
            default: ''
        },
        caption: {
            type: String,
            default: ''
        }
    },
    computed: {
        url() {
            return `https://player.vimeo.com/video/${this.id}?color=ffffff&title=0&byline=0&portrait=0&dnt=1&texttrack=nl`;
        }
    }
};
</script>
