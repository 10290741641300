<template>
    <div class="container">
        <div v-for="block in blocks" :key="block.id" class="row">
            <component :is="block.type" v-if="block.type" :content="block" class="component" />
        </div>
        <template v-if="blocks.length <= 0">
            <skeleton :skeleton="skeleton" />
        </template>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Hero from '../../../_patterns/molecules/hero/hero.vue';
import MapPreview from '../../../_patterns/organisms/map-preview/map-preview.vue';
import Updates from '../../../_patterns/organisms/updates/updates.vue';
import LinkList from '../../../_patterns/organisms/link-list/link-list.vue';
import Skeleton from '~/_patterns/molecules/skeleton/skeleton';

export default {
    components: {
        Skeleton,
        Hero,
        MapPreview,
        Updates,
        LinkList
    },
    props: {
        slug: {
            type: String,
            default: 'home'
        },
        skeleton: {
            type: Array,
            required: false,
            default() { return [7, 18, 10, 8]; }
        }
    },
    computed: {
        ...mapGetters('heroes', {
            heroes: 'bySlug'
        }),
        hero() {
            return this.heroes(this.slug)[0] || {};
        },
        blocks() {
            return this.hero.blocks || [];
        }
    },
    created() {
        this.getHero(this.slug);
    },
    methods: {
        ...mapActions('heroes', {
            getHeroes: 'getHeroes',
            getHero: 'getHero'
        })
    }
};
</script>
