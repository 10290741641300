<template>
    <div class="initiatives">
        <InitiativesMap />
        <InitiativesFooter />
        <InitiativesFilter />
        <InitiativesStart />
    </div>
</template>
<script>

import InitiativesFooter from '../../../_patterns/molecules/initiatives-footer/initiatives-footer.vue';
import InitiativesStart from '../../../_patterns/molecules/initiatives-start/initiatives-start.vue';

export default {
    components: {
        InitiativesMap: () => import('~/_patterns/molecules/initiatives-map/initiatives-map.vue'),
        InitiativesFilter: () => import('~/_patterns/molecules/initiatives-filter/initiatives-filter.vue'),
        InitiativesFooter,
        InitiativesStart
    }
};
</script>
