/* globals _mtm */
/**
 * Custom imports
 */
import Vue from 'vue';
import { sync } from 'vuex-router-sync';
import Inview from 'vueinview';
import VueClipboard from 'vue-clipboard2';
import VueContentPlaceholders from 'vue-content-placeholders';
import VueToast from '../../../../node_modules/vue-toast-notification/src/index';

import router from './router';
import store from './store/index.js';

import craftPreviewAxiosInterceptor from './utilities/craft-preview';

import App from './app.vue';

/**
 * Initialization
 */

Vue.use(Inview);

Vue.use(VueClipboard);
Vue.use(VueToast, {
    position: 'top'
});
Vue.use(VueContentPlaceholders);

/**
* Vue components
*/

sync(store, router);
window.app = new Vue({
    store,
    router,
    components: {
        App
    },
    watch: {
        $route(to) {
            _mtm.push({
                event: 'interaction.pageview',
                data: {
                    pagina_pad: to.fullPath,
                    portaal_naam: 'nieuwamsterdamsklimaat.nl'
                }
            });
        }
    },
    created() {
        if (this.$route.query.token && this.$route.query['x-craft-live-preview']) {
            craftPreviewAxiosInterceptor(
                this.$route.query.token,
                this.$route.query['x-craft-live-preview']
            );
        }
    },
    render: h => h(App)
}).$mount('#app');
