<template>
    <button
        class="click-to-copy btn--clean"
        @click="handleClick"
    >
        {{ hasNativeShare ? shareLabel : copyLabel }}
    </button>
</template>

<script>
'use strict';

export default {
    props: {
        copy: {
            type: String,
            default: '',
            required: true
        },
        copyLabel: {
            type: String,
            default: '',
            required: false
        },
        shareLabel: {
            type: String,
            default: '',
            required: false
        }
    },

    computed: {
        hasNativeShare() {
            const uA = navigator.userAgent,
                vendor = navigator.vendor,
                isSafariDesktop = /Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA);

            if ('share' in navigator && !isSafariDesktop) {
                return true;
            }

            return false;
        },
        url() {
            return document.location.href;
        }
    },

    methods: {
        doCopy() {
            this.$copyText(this.copy).then(() => {
                this.$toast.success('Link gekopieerd');
            }, () => {
                this.$toast.error('Kopieren mislukt');
            });
        },
        handleClick() {
            if (this.hasNativeShare) {
                navigator.share({
                    url: this.url
                });
            } else {
                this.doCopy();
            }
        }
    }
};
</script>
