<template>
    <navlink
        v-if="content.url"
        class="overview-item"
        :url="mapLegacyUri(content.url)"
    >
        <div
            class="overview-item__image"
            :class="{
                'overview-item__image--fallback': !content.image
            }"
        >
            <img :src="content.image ? content.image : fallbackImage" :alt="content.title" :loading="loading">
        </div>
        <div class="overview-item__content">
            <slot>
                <h3 v-if="content.title" class="overview-item__title">
                    {{ content.title }}
                </h3>
                <p v-if="content.date" class="overview-item__date">{{ dateFormatted }}</p>
            </slot>
        </div>
    </navlink>
</template>

<script>

import { getMontName } from '#/utilities/utility';
import loading from '#/mixins/loading';
import navigation, { INTERNAL } from '#/mixins/navigation';

import Navlink from '~/_patterns/atoms/navlink/navlink.vue';

export default {
    components: {
        Navlink
    },
    mixins: [
        loading,
        navigation
    ],
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    },
    data() {
        return {
            fallbackImage: '/img/logo.png',
            INTERNAL
        };
    },
    computed: {
        dateFormatted() {
            const myDate = new Date(this.content.date);
            return myDate.getDate() + ' ' + getMontName(myDate) + ' ' + myDate.getFullYear();
        }
    }
};
</script>
