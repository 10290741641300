<template>
    <router-link :to="`/${uri}`" class="initiative-card item-grid__item">
        <h3 class="initiative-card__title">{{ title }}</h3>

        <div class="initiative-card__image aspect-ratio">
            <vimeo v-if="video" :id="video" />
            <img v-else-if="image" :src="image" alt="" :loading="loading">
            <img v-else :src="fallbackImage" alt="" :loading="loading">
        </div>
    </router-link>
</template>

<script>
import { get } from 'lodash';

import Vimeo from '~/_patterns/atoms/vimeo/vimeo';
import loading from '#/mixins/loading';

export default {
    components: {
        Vimeo
    },
    mixins: [
        loading
    ],
    props: {
        initiative: {
            type: Object,
            required: true,
            default() { return {}; }
        }
    },
    data() {
        return {
            fallbackImage: '/img/logo.png'
        };
    },
    computed: {
        title() {
            return get(this, 'initiative.title', '');
        },
        uri() {
            return get(this, 'initiative.uri', '');
        },
        video() {
            return get(this, 'initiative.video', '');
        },
        image() {
            return get(this, 'initiative.image.0.url', '');
        }
    }
};
</script>
