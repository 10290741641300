<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="hero">
                    <div class="section">
                        <div class="hero__content">
                            <h2 class="hero__title">
                                404
                            </h2>

                            <p class="hero__lead lead">Deze pagina kan niet worden gevonden</p>

                            <call-to-action button-theme="blue" url="/">
                                Ga naar de homepage
                            </call-to-action>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SiteFooter />
    </div>
</template>

<script>

import SiteFooter from '../../organisms/site-footer/site-footer.vue';
import CallToAction from '../../atoms/call-to-action/call-to-action.vue';

export default {
    components: {
        SiteFooter,
        CallToAction
    }
};
</script>
