'use strict';

import { get } from 'lodash';

import { HOME_URI } from '#/store/modules/content-pages';

export const TARGET_BLANK = '_blank';
export const TARGET_SELF = '_self';
export const TARGET_PARENT = '_parent';
export const TARGET_TOP = '_top';

export const INTERNAL = 'internal';
export const EXTERNAL = 'external';

export default {
    methods: {
        mapType(type) {
            switch (type) {
            case 'linkBlock_linkInternal_BlockType':
                return INTERNAL;
            case 'linkBlock_linkExternal_BlockType':
                return EXTERNAL;
            }
        },
        mapURI(link) {
            switch (this.mapType(link.__typename)) {
            case INTERNAL:
            {
                const uri = get(link, 'linkInternal.0.uri', ''),
                    url = this.fixHomeUri(uri);

                return `/${url}`;
            }
            case EXTERNAL:
                if (typeof link === 'string') {
                    if (link[0] === '/') {
                        // If route parameter is a path, create route object with path.
                        return link;
                    }
                }

                return link.linkExternal;
            }
        },
        mapTarget(isTargetBlank) {
            if (isTargetBlank) {
                return TARGET_BLANK;
            }

            return TARGET_SELF;
        },
        fixHomeUri(uri) {
            if (uri === HOME_URI) {
                return '/';
            }

            return uri;
        },
        mapUrlToType(url) {
            if (/^https?:\/\/|^\/\//i.test(url)) {
                return EXTERNAL;
            }

            return INTERNAL;
        },
        mapFullUrlToTarget(url) {
            if (/^https?:\/\/(www\.)?nieuwamsterdamsklimaat\.nl|^\/\/(www\.)?nieuwamsterdamsklimaat\.nl/i.test(url)) {
                return TARGET_SELF;
            } else if (/^https?:\/\/|^\/\//i.test(url)) {
                return TARGET_BLANK;
            }

            return TARGET_SELF;
        },
        mapTypeToTarget(type) {
            if (type === EXTERNAL) {
                return TARGET_BLANK;
            }

            return TARGET_SELF;
        },
        mapLegacyType(type) {
            switch (type) {
            case 'internalLink':
                return INTERNAL;
            case 'externalLink':
                return EXTERNAL;
            }
        },
        mapLegacyUri(uri) {
            if (uri.startsWith(document.location.origin)) {
                return uri.replace(document.location.origin, '');
            }

            return uri;
        }
    }
};
