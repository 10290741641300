import axios from 'axios';

import { getThemeColor } from '#/utilities/utility';

// initial state
const myState = {
        all: [],
        initiatives: [],
        explanationVisible: false,
        filtersVisible: false,
        selectedInitiative: null,
        count: null
    },

    // getters
    myGetters = {
        all: state => state.all,
        count: state => state.count,
        selectedInitiative: state => state.selectedInitiative,
        explanationVisible: state => state.explanationVisible,
        filtersVisible: state => state.filtersVisible,
        getBySlug(state) {
            return slug => state.initiatives.filter(item => item.slug === slug);
        }
    },

    // actions
    actions = {
        setSearchResults({ commit }, results) {
            commit('setSearchResults', results);
        },
        showFilters({ commit }) {
            commit('setFiltersVisible', true);
        },
        hideFilters({ commit }) {
            commit('setFiltersVisible', false);
        },
        showExplanation({ commit }) {
            commit('setExplanationVisibility', true);
        },
        hideExplanation({ commit }) {
            commit('setExplanationVisibility', false);
        },
        setSelectedInitiative({ commit }, initiative) {
            commit('setSelectedInitiative', initiative);
        },
        resetSelectedInitiative({ commit }) {
            commit('setSelectedInitiative', null);
        },
        getAllFromApi({ getters, commit }) {
            if (!getters.initiatives) {
                axios.get('/api/initiatives')
                    .then(({ data }) => {
                        commit('setInitiatives', data.data);
                    });
            }
        },
        getFromApi({ getters, commit }, slug) {
            if (!getters.getBySlug(slug)[0]) {
                return axios.get(`/api/initiatives/${slug}`)
                    .then(({ data }) => {
                        commit('setInitiative', data);
                    });
            }
        },
        getCount({ getters, commit }) {
            // debugger; // eslint-disable-line
            if (!getters.count) {
                axios
                    .get('/api/initiatives-count')
                    .then(({ data }) => {
                        commit('setInitiativesCount', data.total);
                    });
            }
        }
    };

function getIcon(initiative) {
    return '/img/icons/markers/rect-' + (initiative.vimeoId ? 'play' : 'dot') + '-blue.svg';
}

function getIconSize(initiative) {
    return initiative.highlighted ? [64, 64] : [32, 32];
}

// mutations
const mutations = {
    setSearchResults(state, searchResults) {
        state.all = searchResults.map(function(initiative) {
            initiative.themeColor = getThemeColor(initiative.themes[0].toLowerCase());
            initiative.icon = getIcon(initiative);
            initiative.iconSize = getIconSize(initiative);

            if (initiative._geoloc && initiative._geoloc.lat && initiative._geoloc.lng) {
                initiative.latLng = [initiative._geoloc.lat, initiative._geoloc.lng];
            }

            return initiative;
        });
    },
    setFiltersVisible(state, isVisible) {
        state.filtersVisible = isVisible;
    },
    setExplanationVisibility(state, isVisible) {
        state.explanationVisible = isVisible;
    },
    setSelectedInitiative(state, selected) {
        state.selectedInitiative = selected;
    },
    setInitiatives(state, initiatives) {
        state.initiatives = initiatives;
    },
    setInitiative(state, initiative) {
        state.initiatives = [
            ...state.initiatives,
            initiative
        ];
    },
    setInitiativesCount(state, initiativesCount) {
        state.count = initiativesCount;
    }
};

export default {
    namespaced: true,
    state: myState,
    getters: myGetters,
    actions,
    mutations
};
