<template>
    <div>
        <label class="form-label">{{ field.label }}</label>
        <div v-if="field.instructions" class="form-field-instructions">{{ field.instructions }}</div>
        <div class="checkbox-radio-container form__grid form__grid2">
            <div v-for="option in field.options" :key="option.value" class="form__cell checkbox-radio-item">
                <input :id="'checkbox-'+option.value" :name="field.handle" type="checkbox" :value="option.value">
                <label :for="'checkbox-'+option.value">{{ option.label }} <div class="checkbox-square" /></label>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        field: {
            type: Object,
            required: true
        }
    }
};
</script>
