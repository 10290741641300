<template>
    <div>
        <template v-for="(section, key) in sections">
            <component :is="map[section.__typename]" v-if="section.__typename" :key="key" :content="section" class="component" />
        </template>
    </div>
</template>

<script>
import { get } from 'lodash';

import AdviceGroup from './blocktypes/advice-group.vue';
import InitiativesCollection from './blocktypes/initiatives-collection.vue';
import Wysiwyg from './blocktypes/wysiwyg.vue';
import SaveResults from './blocktypes/save-results.vue';

export const adviceGroupBlockType = 'flexibleSection_adviceGroup_BlockType';

const graphToComponentMapping = {
    [adviceGroupBlockType]: AdviceGroup,
    flexibleSection_initiativesBlock_BlockType: InitiativesCollection,
    flexibleSection_wywisyg_BlockType: Wysiwyg,
    flexibleSection_duurzameWegwijzerResultatenOpslaan_BlockType: SaveResults
};

export default {
    props: {
        sections: {
            type: Array,
            required: false,
            default: () => { return []; }
        }
    },
    computed: {
        map: () => graphToComponentMapping
    }
};

/**
 * Function to easily filter blocks by passing the wanted blockType.
 *
 * @param type
 * @returns {function(*=): boolean}
 */
export function filterBlocks(type) {
    return (v) => {
        return get(v, '__typename', '') === type;
    };
}
</script>
