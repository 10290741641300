<template>
    <div class="repeater-block section">
        <div :id="'image-block-' + content.hash" class="repeater-block__hash" />
        <div class="component component--narrow component--left">
            <h2 v-if="content.header" class="repeater-block__title" v-text="content.header" />
            <div v-if="content.text" class="repeater-block__text" v-html="content.text" /> <!-- eslint-disable-line vue/no-v-html -->
        </div>
        <div
            class="repeater-block__blocks item-grid item-grid--gutter-vertical-wide"
            data-columns-xs="1"
            data-columns-sm="1"
            data-columns-md="2"
            data-columns-lg="2"
            data-columns-xl="2"
        >
            <div
                v-for="(block, key) in content.blocks"
                :key="key"
                :class="`repeater-block__block--${block.image.layout}`"
                class="repeater-block__block item-grid__item"
            >
                <div class="repeater-block__block__image" :class="`block-image--${block.image.layout}`">
                    <img v-if="block.image" :src="block.image.url" alt="" :loading="loading">
                </div>
                <h3 class="repeater-block__block__title" v-text="block.title" />
                <p class="repeater-block__block__text">
                    <v-clamp autoresize :max-lines="block.image.layout === 'fullWidth' ? 3 : 2">{{ block.text }}</v-clamp>
                </p>
                <div class="repeater-block__block__link">
                    <a
                        v-if="block.link.uri && block.link.text"
                        :href="block.link.uri"
                        :target="getLinkTarget(block.link.uri)"
                        class="block-link"
                        :class="{
                            'block-link--link': block.link.layout === 'link',
                            'block-link--button': block.link.layout === 'button',
                            'btn': block.link.layout === 'button',
                            'btn---tertiary': block.link.layout === 'button'
                        }"
                    >
                        {{ block.link.text }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VClamp from 'vue-clamp';

import linkMixin from '#/mixins/link';
import loading from '#/mixins/loading';

export default {
    components: {
        VClamp
    },
    mixins: [
        linkMixin,
        loading
    ],
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    }
};
</script>
