'use strict';

/**
 * Intercept all API requests and add the needed token params
 */

import axios from 'axios';
import URI from 'urijs';
import { isNil } from 'lodash';

export default (token, preview, hostname = document.location.hostname) => {
    if (!isNil(token) && !isNil(preview)) {
        // Add a global request interceptor
        axios.interceptors.request.use((config) => {
            const url = new URI(config.url);

            if (url.hostname() === '' || url.hostname() === hostname) {
                return {
                    ...config,
                    params: {
                        ...config.params,
                        'x-craft-live-preview': preview,
                        token
                    }
                };
            }

            return config;
        });
    }
};
