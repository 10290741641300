<template>
    <footer class="initiatives-footer">
        <div class="container">
            <div class="initiatives-footer__grid">
                <div class="initiatives-footer__cell initiatives-footer__cell-title"><h3>Wat doen Amsterdammers?</h3></div>
                <div class="initiatives-footer__cell initiatives-footer__cell-info" @click="showExplanation">?</div>
                <div class="initiatives-footer__cell initiatives-footer__cell-filters">
                    <button class="btn btn--primary initiatives-footer__filter-btn" @click="showFilters">Filters</button>
                </div>
                <div class="initiatives-footer__cell initiatives-footer__cell-add">
                    <router-link to="/voeg-jouw-initiatief-toe">
                        <button class="btn btn--primary btn--plus initiatives-footer__cta">Voeg jouw project toe</button>
                    </router-link>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    methods: {
        ...mapActions('initiatives', {
            showFilters: 'showFilters',
            showExplanation: 'showExplanation'
        })
    }
};
</script>
