<template>
    <div v-if="tool" class="tool">
        <!-- Tool hero -->
        <div class="hero tool__hero">
            <div class="section section--flatter">
                <div class="component">
                    <div
                        class="item-grid item-grid--gutter-vertical-wide"
                    >
                        <div v-if="tool.image" class="item-grid__item tool__image-container">
                            <img v-if="tool.image" :src="tool.image.url" :alt="tool.image.title" class="tool__image" :loading="loading">
                        </div>

                        <div class="item-grid__item tool__intro-container">
                            <h1 v-if="tool.title" class="tool__title">{{ tool.title }}</h1>
                            <p v-if="tool.description" class="tool__intro">{{ tool.description }}</p>

                            <navlink
                                v-if="tool.callToAction.url"
                                :url="tool.callToAction.url"
                                classes="tool__cta btn btn--primary"
                                :target="mapFullUrlToTarget(tool.callToAction.url)"
                                :type="mapUrlToType(tool.callToAction.url)"
                            >
                                {{ tool.callToAction.text }}
                            </navlink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Tool content -->
        <section class="tool__content">
            <div v-for="(matrix, key) in tool.matrix" :key="key" class="row">
                <component :is="matrix.type" v-if="matrix.type" :content="matrix" class="component" />
            </div>
        </section>

        <!-- Tool filtered overview -->
        <section v-if="tool.filteredOverview && tool.filteredOverview.length" class="tool__filtered-overview">
            <div class="section section--flatter">
                <div class="component">
                    <filtered-overview
                        :title="tool.filteredOverviewTitle"
                        :categories="tool.filteredOverview"
                    />
                </div>
            </div>
        </section>

        <!-- Site footer -->
        <SiteFooter />
    </div>
    <div v-else class="tool">
        <skeleton />
    </div>
</template>

<script>

import { mapGetters, mapActions, mapState } from 'vuex';

import SiteFooter from '~/_patterns/organisms/site-footer/site-footer.vue';
import contentPageHero from '~/_patterns/molecules/content-page-hero/content-page-hero.vue';
import contentPageIndexHero from '~/_patterns/molecules/content-page-index-hero/content-page-index-hero.vue';
import contentPageContactHero from '~/_patterns/molecules/content-page-contact-hero/content-page-contact-hero.vue';
import GenericHtml from '~/_patterns/organisms/generic-html/generic-html.vue';
import RepeaterBlock from '~/_patterns/organisms/repeater-block/repeater-block.vue';
import LinkList from '~/_patterns/organisms/link-list/link-list.vue';
import FilteredOverview from '~/_patterns/organisms/filtered-overview/filtered-overview.vue';
import InitiativesCallToAction from '~/_patterns/molecules/initiative-cta/initiative-cta.vue';
import Navlink from '~/_patterns/atoms/navlink/navlink';
import Skeleton from '~/_patterns/molecules/skeleton/skeleton';
import FAQ from '~/_patterns/organisms/faq/faq.vue';
import EcForm from '~/_patterns/molecules/ec-form/ec-form';

import navigation from '#/mixins/navigation';
import loading from '#/mixins/loading';

export default {
    components: {
        Skeleton,
        energyChallengeEmailForm: EcForm,
        Navlink,
        SiteFooter,
        contentPageHero,
        contentPageContactHero,
        contentPageIndexHero,
        InitiativesCallToAction,
        GenericHtml,
        RepeaterBlock,
        LinkList,
        FilteredOverview,
        faq: FAQ
    },
    mixins: [
        navigation,
        loading
    ],
    computed: {
        tool() {
            const item = this.getBySlug(this.routeParams.slug)[0];
            if (typeof item === 'object' && item.title) {
                document.title = item.title;
                this.$router.options.setDocumentTitle(item.title);
            }

            return item;
        },
        ...mapGetters('tools', {
            getBySlug: 'getBySlug'
        }),
        ...mapState('route', {
            routeParams: 'params'
        })
    },
    created() {
        this
            .getTool(this.routeParams.slug)
            .catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            });
    },
    methods: {
        ...mapActions('tools', {
            getTool: 'getTool'
        })
    }
};
</script>
