<template>
    <div class="faq">
        <div
            v-for="(row, key) in rows"
            :key="`faq-item${key}`"
            :data-active="open === key"
            class="faq__item"
        >
            <button class="faq__heading" @click="toggleQuestion(key)">
                <h4>
                    {{ row.title }}
                </h4>

                <div class="faq__heading__icon" />
            </button>

            <div v-show="open === key" class="faq__content" v-html="row.content" /> <!-- eslint-disable-line -->
        </div>
    </div>
</template>

<script>
'use strict';

export default {
    props: {
        rows: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        }
    },
    data() {
        return {
            open: null
        };
    },
    methods: {
        toggleQuestion(questionNumber) {
            if (this.open === questionNumber) {
                this.open = null;
            } else {
                this.open = questionNumber;
            }
        }
    }
};
</script>
