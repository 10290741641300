const themeColors = {
        energiebesparing: 'blue',
        'duurzame opwek': 'red',
        mobiliteit: 'purple',
        'haven & industrie': 'teal',
        'biomassa & voedsel': 'teal',
        'bewustwording & educatie': 'green',
        'aardgasvrij & voedsel': 'green',
        default: 'pink'
    },

    monthNames = ['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december'];

function getThemeColor(theme) {
    return themeColors[theme] || themeColors.default;
}

function getMontName(myDate) {
    return monthNames[myDate.getMonth()];
}

export {
    getThemeColor,
    getMontName
};
