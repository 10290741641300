<template>
    <div>
        <button type="submit" class="call-to-action btn  btn--primary btn--no-mobile-icon">{{ field.labelNext }}</button>
    </div>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true
        }
    }
};
</script>
