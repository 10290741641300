<template>
    <div>
        <HeroHome />
        <Heroes />
    </div>
</template>

<script>
import Heroes from '../../../_patterns/organisms/heroes/heroes.vue';
import HeroHome from '../../../_patterns/organisms/hero-home/hero-home.vue';

export default {
    components: {
        Heroes,
        HeroHome
    },
    props: {}
};
</script>
