<template>
    <div class="content-page-hero hero hero--left-align" :class="classSelectors">
        <div v-if="content.photoBorder" class="hero__photo-breaker" />

        <div class="section section--flatter" :class="containerClasses">
            <div class="component" :class="componentClasses">
                <div
                    class="item-grid item-grid--gutter-vertical-wide"
                    data-columns-xs="1"
                    data-columns-sm="1"
                    :data-columns-md="desktopColumns"
                    :data-columns-lg="desktopColumns"
                    :data-columns-xl="desktopColumns"
                >
                    <div class="item-grid__item">
                        <h2 v-if="content.contentHeroTitle">{{ content.contentHeroTitle }}</h2>
                        <div v-if="content.contentHeroSubTitle" class="hero__text" v-html="content.contentHeroSubTitle" /> <!-- eslint-disable-line vue/no-v-html -->
                    </div>
                    <div v-if="content.contentPageImageRight" class="item-grid__item hero__img-full-width">
                        <img class="content-page-hero__image hero__img-full-width" :src="content.contentPageImageRight.url" :loading="loading">
                    </div>
                    <div v-if="content.contentPageImage" class="item-grid__item">
                        <img :src="content.contentPageImage.url" :loading="loading">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

'use strict';

import loading from '#/mixins/loading';

export default {
    mixins: [
        loading
    ],
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    },
    computed: {
        classSelectors() {
            if (this.content.photoBorder) {
                // Has photo-border
                return 'hero--photobreaker';
            }
            return '';
        },
        containerClasses() {
            if (this.content.contentHeroBackgroundColor && this.content.contentHeroBackgroundColor.value) {
                // Has background color
                return 'background background--bleed background--' + this.content.contentHeroBackgroundColor.value;
            }
            return '';
        },
        desktopColumns() {
            // If there is a right image then show two columns
            return this.content.contentPageImageRight ? '2' : '1';
        },
        componentClasses() {
            // If there is a right image then make the component wide
            return this.content.contentPageImageRight ? 'component--wide' : 'component--narrow component--left';
        }
    }
};
</script>
