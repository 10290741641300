<template>
    <footer class="footer">
        <div class="container footer__grid">
            <div class="footer__header__title">
                <p>Nieuw Amsterdams Klimaat is een initiatief van gemeente Amsterdam.</p>
                <img src="/img/footer-logo.png" alt="Gemeente Amsterdam" :loading="loading">
            </div>

            <ul class="footer__link-list">
                <li><NavItem :route="{ name: 'contentPage', params: { slug: 'wat-kan-jij-doen' } }">Wat kan jij doen?</NavItem></li>
                <li><NavItem :route="{ name: 'Initiatieven' }">Wat doen Amsterdammers?</NavItem></li>
                <li><NavItem :route="{ name: 'contentPage', params: { slug: 'actueel' } }">Actueel</NavItem></li>
                <li><NavItem :route="{ name: 'contentPage', params: { slug: 'over-ons' } }">Over ons</NavItem></li>
                <li><NavItem :route="{ name: 'contentPage', params: { slug: 'contact' } }">Contact</NavItem></li>
            </ul>

            <div class="footer__privacy">
                <NavItem :route="{ name: 'contentPage', params: { slug: 'privacy' } }">Over privacy en data</NavItem>
                <li class="nav-item">
                    <a href="https://www.amsterdam.nl/privacy/beveiligingslek/">Responsible Disclosure</a>
                </li>
            </div>
        </div>
    </footer>
</template>

<script>
import NavItem from '~/_patterns/atoms/nav-item/nav-item.vue';
import loading from '#/mixins/loading';

export default {
    components: {
        NavItem
    },
    mixins: [
        loading
    ]
};
</script>
