<template>
    <div v-if="showComponent" class="initiative-cta background background--grey background--bleed section section--flat">
        <h4 v-if="title" class="initiative-cta__title" v-text="title" />
        <div v-if="text" class="initiative-cta__text" v-html="text" /> <!-- eslint-disable-line vue/no-v-html -->

        <navlink v-if="linkUrl" :url="linkUrl" :target="mapTypeToTarget(mapUrlToType(linkUrl))" :type="mapUrlToType(linkUrl)">
            <button class="initiative-cta__btn btn" v-text="linkText" />
        </navlink>
        <navlink v-else-if="mailtoUrl" :url="mailtoUrl" type="external">
            <button class="initiative-cta__btn btn" v-text="linkText" />
        </navlink>
    </div>
</template>

<script>
import { get, isEmpty } from 'lodash';
import Navlink from '~/_patterns/atoms/navlink/navlink.vue';
import navigation from '#/mixins/navigation';

export default {
    components: {
        Navlink
    },
    mixins: [
        navigation
    ],
    props: {
        content: {
            type: Object,
            default: () => { return {}; }
        }
    },
    computed: {
        title() {
            if (this.content.title) {
                return this.content.title;
            }

            return 'Hoe kan jij dit doen?';
        },
        text() {
            if (this.content.text) {
                return this.content.text;
            }

            return 'Bekijk welke mogelijke subsidies de gemeente aanbiedt';
        },
        linkText() {
            if (this.content.linkText) {
                return this.content.linkText;
            }

            return 'Vraag subsidie aan';
        },
        linkUrl() {
            return get(this, 'content.linkUrl');
        },
        mailtoUrl() {
            const mailto = get(this, 'content.mailtoUrl');

            if (isEmpty(mailto)) {
                return null;
            }

            return `mailto:${mailto}`;
        },
        showComponent() {
            return get(this, 'content.linkUrl', false) || get(this, 'content.mailtoUrl', false);
        }
    }
};
</script>
