<template>
    <div class="dw-intro-block background--blue background--bleed">
        <div id="wegwijzer" ref="hash" class="dw-intro-block__hash" />
        <div class="dw-intro-block__text">
            <h2>{{ title }}</h2>

            <div v-html="text" /> <!-- eslint-disable-line vue/no-v-html -->

            <router-link :to="{ name: 'duurzame-wegwijzer' }" class="btn btn--secondary">{{ ctaText }}</router-link>

            <div v-if="disclaimer" v-html="disclaimer" /> <!-- eslint-disable-line vue/no-v-html -->
        </div>

        <img class="dw-intro-block__image" src="/img/duurzame-wegwijzer/logo-big.png" alt="Duurzame wegwijzer" :loading="loading">
    </div>
</template>

<script>
import { get } from 'lodash';

import { isSafari } from '#/utilities/useragent';
import loading from '#/mixins/loading';

export default {
    mixins: [
        loading
    ],
    props: {
        content: {
            type: Object,
            default: () => {
                return {
                    title: '',
                    text: '',
                    ctaText: ''
                };
            }
        }
    },
    computed: {
        title() {
            return get(this, 'content.title', '');
        },
        text() {
            // ik injecteer hier een span zodat de p:last-child niet werkt in dit blok, anders klopt de layout niet.
            return `${get(this, 'content.text', '')}<span />`;
        },
        ctaText() {
            return get(this, 'content.ctaText', '');
        },
        disclaimer() {
            return get(this, 'content.disclaimer', '');
        }
    },
    mounted() {
        if (window.location.hash === '#wegwijzer') {
            if (isSafari) {
                /*
                 * Safari does not support scroll-margin-top when using scrollIntoView,
                 * so we just manually scroll to the right position. We can't use
                 * smooth scrolling because we need to scroll twice, to the element and
                 * then a bit down to account for the scroll-margin-top.
                 */
                this.$refs.hash.scrollIntoView();
                window.scrollBy(0, -80); // or whatever the height of the nav is.
                return;
            }

            this.$refs.hash.scrollIntoView({ behavior: 'smooth' });
        }
    }
};
</script>
