<template>
    <div class="site-logo" :class="classSelectors">
        <router-link v-if="isLink" to="/">
            <div class="site-logo__img">
                <img :src="src" alt="" :loading="loading">
            </div>
            <div v-if="showText" class="site-logo__text">
                Nieuw
                <br>Amsterdams
                <br>Klimaat
            </div>
        </router-link>

        <template v-else>
            <div class="site-logo__img">
                <img :src="src" alt="" :loading="loading">
            </div>
            <div v-if="showText" class="site-logo__text">
                Nieuw
                <br>Amsterdams
                <br>Klimaat
            </div>
        </template>
    </div>
</template>

<script>
import loading from '#/mixins/loading';

export default {
    mixins: [
        loading
    ],
    props: {
        theme: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        },
        isLink: {
            type: Boolean,
            default: false
        },
        showText: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        src() {
            return this.isIE() ? '/img/logo.png' : '/img/logo.svg';
        },
        classSelectors() {
            let classes = this.theme === 'dark' ? 'site-logo--dark' : '';

            if (this.size === 'large') {
                classes = classes.concat(' ', 'site-logo--large');
            } else if (this.size === 'xl') {
                classes = classes.concat(' ', 'site-logo--xl');
            }

            return classes;
        }
    },
    methods: {
        isIE() {
            const ua = navigator.userAgent;
            return ua.includes('MSIE ') || ua.includes('Trident/');
        }
    }
};
</script>
