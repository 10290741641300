import axios from 'axios';

// initial state
const myState = {
        all: []
    },

    // getters
    myGetters = {
        all: state => state.all,
        getById(state) {
            return id => state.all.filter(item => item.id === id);
        },
        getBySlug(state) {
            return slug => state.all.filter(item => item.slug === slug);
        },
        getWithoutId(state) {
            return id => state.all.filter(item => item.id !== id);
        }
    },

    // actions
    actions = {
        getUpdates({ commit, getters }) {
            if (getters.all.length === 0) {
                return axios
                    .get('/api/updates')
                    .then(({ data }) => {
                        commit('setUpdates', data.data);
                    });
            }
        }
    },

    // mutations
    mutations = {
        setUpdates(state, updates) {
            state.all = updates;
        }
    };

export default {
    namespaced: true,
    state: myState,
    getters: myGetters,
    actions,
    mutations
};
