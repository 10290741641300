<template>
    <div>
        <form v-if="!isSubmitted" method="POST" class="vue-form" @submit.prevent="submitForm">
            <FormFields :rows="form.rows" :errors="errors" />
            <div v-if="hasErrors" class="errors">
                Vul aub alle verplichte velden in
            </div>
        </form>
    </div>
</template>
<script>

import axios from 'axios';
import FormFields from '../../../_patterns/molecules/vue-form-fields/vue-form-fields.vue';

export default {
    components: {
        FormFields
    },
    props: {
        formHandle: {
            type: Object,
            default: () => { return {}; }
        }
    },
    data() {
        return {
            form: {},
            isSubmitted: false,
            hasErrors: false,
            formData: {},
            errors: {}
        };
    },
    created() {
        this.showForm();
    },
    methods: {
        getFormData() {
            const form = document.getElementsByTagName('form')[0];
            return new FormData(form);
        },
        addTokensToFormData(formData) {
            for (const key in this.formData) {
                if (key) {
                    formData.append(key, this.formData[key]);
                }
            }
            return formData;
        },
        responseHandler(request) {
            if (request.status !== 200) {
                return false;
            }

            const response = JSON.parse(request.response);

            // Form submit success
            if (response.success && response.success === true) {
                this.isSubmitted = true;

                if (response.returnUrl && response.returnUrl !== '') {
                    this.$router.push(response.returnUrl);
                }

                return true;
            }

            // Store fresh honeypot token to form data for next request
            if (response.honeypot) {
                this.formData[response.honeypot.name] = response.honeypot.hash;
            }

            // Show errors for each field
            if (response.errors) {
                this.errors = response.errors;
                this.hasErrors = true;
            }
        },
        submitForm() {
            const parent = this,
                formData = this.addTokensToFormData(this.getFormData()),
                request = new XMLHttpRequest();
            request.open('POST', '/', true);
            request.setRequestHeader('Cache-Control', 'no-cache');
            request.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
            request.setRequestHeader('HTTP_X_REQUESTED_WITH', 'XMLHttpRequest');
            request.onload = function() {
                parent.responseHandler(request);
            };

            // Do the request
            request.send(formData);
        },
        mapPage() {
            const parent = this;
            if (typeof parent.form.composer.layout[0] !== 'undefined') {
                const page = parent.form.composer.layout[0];
                page.forEach((row) => {
                    parent.addFieldsToRow(row);
                });
                return page;
            }
        },
        addFieldsToRow(row) {
            const parent = this;
            row.fields = [];
            row.columns.forEach((columnId) => {
                row.fields.push(parent.form.composer.properties[columnId]);
            });
        },
        showForm() {
            axios.get('/freeform.json?handle=' + this.formHandle.handle).then((response) => {
                this.form = response.data[0].form;
                this.formData = response.data[1].formData;
                this.form.rows = this.mapPage();
            });
        }
    }
};
</script>
