<template>
    <content-placeholders>
        <div class="row">
            <div class="component">
                <content-placeholders-img />
                <content-placeholders-heading />
            </div>
        </div>
        <div v-for="(lines, key) in skeleton" :key="key" class="row">
            <div class="component">
                <content-placeholders-text :lines="lines" />
            </div>
        </div>
    </content-placeholders>
</template>

<script>
export default {
    props: {
        skeleton: {
            type: Array,
            required: false,
            default() { return [3, 7, 2, 6, 4, 6, 2, 6, 5, 8, 10]; }
        }
    }
};
</script>
