
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"defaultPageContent"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"advicePages_advicePages_Entry"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"__typename"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"uri"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"slug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"title"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"flexibleSection"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"flexibleSections"},"directives":[]}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"defaultAdvicePageContent"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"advicePages_advicePages_Entry"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"defaultPageContent"},"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"customSlug"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"text"},"arguments":[],"directives":[]},{"kind":"Field","alias":{"kind":"Name","value":"image"},"name":{"kind":"Name","value":"imageContentPage"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"defaultImageAsset"},"directives":[]}]}}]}}],"loc":{"start":0,"end":489}};
    doc.loc.source = {"body":"#import \"#/graphql/fragments/flexibleSections/flexibleSections.graphql\"\n#import \"#/graphql/fragments/defaultImageAsset.graphql\"\n\nfragment defaultPageContent on advicePages_advicePages_Entry {\n    __typename\n    id\n    uri\n    slug\n    title\n    flexibleSection {\n        ... flexibleSections\n    }\n}\n\nfragment defaultAdvicePageContent on advicePages_advicePages_Entry {\n   ... defaultPageContent\n    customSlug\n    text\n    image: imageContentPage {\n        ... defaultImageAsset\n    }\n}\n\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("#/graphql/fragments/flexibleSections/flexibleSections.graphql").definitions));
doc.definitions = doc.definitions.concat(unique(require("#/graphql/fragments/defaultImageAsset.graphql").definitions));


      module.exports = doc;
    
