export default {
    props: {
        loading: {
            type: String,
            required: false,
            default: 'lazy',
            validator: v => ['', 'eager', 'lazy'].includes(v)
        }
    }
};
