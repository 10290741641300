<template>
    <div>
        <header class="header" :class="{ 'scroll-up': hideHeader && !navExpanded, 'header--clean': hideNavigationLogo }">
            <div class="container">
                <div class="header__bar">
                    <div class="header__logo">
                        <site-logo :is-link="true" />
                    </div>
                    <div class="header__menu">
                        <main-nav />
                    </div>
                </div>
            </div>
        </header>
        <hamburger-menu :class="{ 'scroll-up': hideHeader && !navExpanded}" @nav-open="navOpen" />
        <div v-if="isIE" class="ie-11-warning">
            Deze website is op dit moment niet geoptimaliseerd voor
            Internet Explorer 11 en lager. We raden u aan een andere
            modernere browser te gebruiken voor een optimale ervaring.
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';
import MainNav from '../../../_patterns/molecules/main-nav/main-nav.vue';
import SiteLogo from '../../../_patterns/atoms/site-logo/site-logo.vue';
import HamburgerMenu from '../../../_patterns/organisms/hamburger-menu/hamburger-menu.vue';

const headerHeight = 80;
let previousScrollTop,
    scrollTop = 0;

export default {
    components: {
        MainNav,
        SiteLogo,
        HamburgerMenu
    },
    data() {
        return {
            hideHeader: false,
            navExpanded: false
        };
    },
    computed: {
        ...mapGetters('heroHome', {
            hideNavigationLogo: 'logoVisible'
        }),
        isIE() {
            return navigator.userAgent.includes('MSIE') || navigator.appVersion.includes('Trident/');
        }
    },
    watch: {
        '$route'() {
            this.hideHeader = false;
        }
    },
    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    },
    methods: {
        updateScroll() {
            scrollTop = window.scrollY;
            if (previousScrollTop < scrollTop && scrollTop > headerHeight + headerHeight) {
                this.hideHeader = true;
            } else if (previousScrollTop > scrollTop && !(scrollTop <= headerHeight)) {
                this.hideHeader = false;
            }
            previousScrollTop = scrollTop;
        },
        navOpen(show) {
            this.navExpanded = show;
        }
    },
    destroy() {
        window.removeEventListener('scroll', this.updateScroll);
    }
};
</script>
