<template>
    <div class="initiatives_content_page">
        <div v-if="initiative" class="initiative_single">
            <div class="container">
                <div class="row">
                    <div class="component">
                        <div class="section">
                            <div class="component component--narrow component--left">
                                <h2>{{ initiative.title }}</h2>

                                <div class="initiative__image_single">
                                    <img :src="initiative.image.url" alt="" :loading="loading">
                                </div>
                            </div>
                            <div class="component component--narrow component--left">
                                <p>{{ initiative.description }}</p>

                                <div v-if="initiative.vimeoId" class="initiative__vimeo">
                                    <Vimeo :id="initiative.vimeoId" />
                                </div>

                                <div v-html="initiative.contentLong" /> <!-- eslint-disable-line vue/no-v-html -->

                                <div v-if="initiative.url" data-spacing-top="lg">
                                    <navlink :url="initiative.url" :target="TARGET_BLANK" :type="EXTERNAL" classes="btn btn--primary">
                                        Bezoek de website
                                    </navlink>
                                </div>
                            </div>
                        </div>

                        <initiatives-call-to-action v-if="initiative.callToAction" :content="initiative.callToAction" />
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="initiative_single">
            <div class="container">
                <skeleton />
            </div>
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';

import SiteFooter from '~/_patterns/organisms/site-footer/site-footer.vue';
import Vimeo from '~/_patterns/atoms/vimeo/vimeo.vue';
import InitiativesCallToAction from '~/_patterns/molecules/initiative-cta/initiative-cta.vue';
import Navlink from '~/_patterns/atoms/navlink/navlink.vue';
import Skeleton from '~/_patterns/molecules/skeleton/skeleton';

import { TARGET_BLANK, EXTERNAL } from '#/mixins/navigation';
import loading from '#/mixins/loading';

export default {
    components: {
        Skeleton,
        SiteFooter,
        Vimeo,
        InitiativesCallToAction,
        Navlink
    },
    mixins: [
        loading
    ],
    data() {
        return {
            TARGET_BLANK,
            EXTERNAL
        };
    },
    computed: {
        initiative() {
            return this.getBySlug(this.routeParams.slug)[0];
        },
        ...mapGetters('initiatives', {
            getBySlug: 'getBySlug'
        }),
        ...mapState('route', {
            routeParams: 'params'
        })
    },
    created() {
        this
            .getFromApi(this.routeParams.slug)
            .catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            });
    },
    methods: {
        ...mapActions('initiatives', {
            getFromApi: 'getFromApi'
        })
    }
};
</script>
