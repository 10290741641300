import axios from 'axios';

// initial state
const myState = {
        all: []
    },

    // getters
    myGetters = {
        all: state => state.all,
        getBySlug(state) {
            return slug => state.all.filter(item => item.slug === slug);
        }
    },

    // actions
    actions = {
        getTools({ getters, commit }, { force = false }) {
            if (!getters.all || force) {
                axios.get('/api/tools')
                    .then(({ data }) => {
                        commit('setTools', data.data);
                    });
            }
        },
        getTool({ getters, commit }, slug) {
            if (!getters.getBySlug(slug)[0]) {
                return axios.get(`/api/tools/${slug}`)
                    .then(({ data }) => {
                        commit('setTool', data);
                    });
            }
        }
    },

    // mutations
    mutations = {
        setTools(state, tools) {
            state.all = tools;
        },
        setTool(state, tool) {
            state.all = [
                ...state.all,
                tool
            ];
        }
    };

export default {
    namespaced: true,
    state: myState,
    getters: myGetters,
    actions,
    mutations
};
