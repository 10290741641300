<template>
    <div v-if="categories && categories.length" class="filtered-overview">
        <h2 v-if="title" class="filtered-overview__title">{{ title }}</h2>
        <ul class="filtered-overview__filters">
            <li
                v-for="(group, key) in categories"
                :key="key"
                :class="{
                    active: currentCategory.category === group.category
                }"
                class="filtered-overview__filter"
                @click="selectCategory(group)"
            >
                {{ group.category }}
            </li>
        </ul>

        <div class="filtered-overview__items">
            <overview-item
                v-for="(item, key) in currentCategory.items"
                :key="key"
                :content="mapItem(item)"
            />
        </div>
    </div>
</template>

<script>
import linkMixin from '../../../_assets/js/app/mixins/link';
import OverviewItem from '../../../_patterns/molecules/overview-item/overview-item.vue';

export default {
    components: {
        OverviewItem
    },

    mixins: [linkMixin],

    props: {
        categories: {
            type: Array,
            default: () => { return []; }
        },
        title: {
            type: String,
            default: '',
            required: false
        }
    },

    data() {
        return {
            currentCategory: this.categories[0]
        };
    },

    mounted() {
        if (localStorage.activeFilter) {
            this.currentCategory = JSON.parse(localStorage.activeFilter);
        }
    },

    methods: {
        selectCategory(category) {
            this.currentCategory = category;
            localStorage.activeFilter = JSON.stringify(category);
        },

        mapItem(item) {
            return {
                url: item.url,
                image: item.image,
                title: item.title
            };
        }
    }
};
</script>
