<template>
    <nav class="main-nav">
        <NavItem :route="{ name: 'contentPage', params: { slug: 'wat-kan-jij-doen' } }">Wat kan jij doen?</NavItem>
        <NavItem :route="{ name: 'Initiatieven' }">Wat doen Amsterdammers?</NavItem>
        <NavItem :route="{ name: 'contentPage', params: { slug: 'actueel' } }">Actueel</NavItem>
        <NavItem :route="{ name: 'contentPage', params: { slug: 'over-ons' } }">Over ons</NavItem>
        <NavItem :route="{ name: 'contentPage', params: { slug: 'contact' } }">Contact</NavItem>
        <NavItem :route="{ name: 'contentPage', params: { slug: 'privacy' } }" class="main-nav__mobile main-nav__footer">Over privacy en data</NavItem>
    </nav>
</template>

<script>
import NavItem from '../../../_patterns/atoms/nav-item/nav-item.vue';

export default {
    components: { NavItem }
};
</script>
