<template>
    <div class="splash-loader">
        <div class="splash-loader__inner">
            <h2><img src="/img/duurzame-wegwijzer/logo-small.png" alt="" :loading="loading"> Resultaten <span>aan het</span> ophalen…</h2>
        </div>
    </div>
</template>

<script>
'use strict';

import loading from '#/mixins/loading';

export default {
    mixins: [
        loading
    ]
};
</script>
