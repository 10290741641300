<template>
    <div id="app" class="page">
        <site-header />
        <router-view :key="$route.path" class="router" />
    </div>
</template>

<script>
import SiteHeader from '~/_patterns/organisms/site-header/site-header';

export default {
    components: {
        SiteHeader
    }
};
</script>
