<template>
    <generic-html :content="wrappedContent" />
</template>

<script>
import { get } from 'lodash';
import GenericHtml from '~/_patterns/organisms/generic-html/generic-html.vue';

export default {
    components: {
        GenericHtml
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => { return {}; }
        }
    },
    computed: {
        wrappedContent() {
            return {
                backgroundColor: get(this, 'content.backgroundColor', 'white'),
                html: get(this, 'content.wysiwyg', '')
            };
        }
    }
};
</script>
