<template>
    <div class="updates section">
        <h2 class="updates__header">{{ title || 'Actueel' }}</h2>

        <template v-if="updates.length > 0">
            <pagination v-if="paginated" :items="updates" @paginate="paginate">
                <overview-item
                    v-for="update in paginatedData"
                    :key="update.id"
                    class="updates__update"
                    :content="mapUpdate(update)"
                />
            </pagination>

            <template v-else>
                <overview-item v-for="update in latestUpdates" :key="update.id" class="updates__overview-item" :content="mapUpdate(update)" />
            </template>
        </template>

        <div v-if="showLinkToMoreUpdates" class="updates__footer">
            <router-link :to="{ name: 'contentPage', params: { slug: 'actueel' } }">
                <button class="btn updates__more-button">Meer actueel</button>
            </router-link>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import OverviewItem from '../../../_patterns/molecules/overview-item/overview-item.vue';
import Pagination from '../../../_patterns/molecules/pagination/pagination.vue';

export default {
    components: {
        OverviewItem,
        Pagination
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        paginated: {
            type: Boolean,
            default: false
        },
        showLinkToMoreUpdates: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            paginatedData: [],
            maxIterations: 3

        };
    },
    computed: {
        latestUpdates() {
            return this.updates.slice(0, this.maxIterations);
        },
        ...mapGetters('updates', {
            updates: 'all'
        })
    },
    created() {
        this.getUpdates();
    },
    methods: {
        paginate(paginatedData) {
            this.paginatedData = paginatedData;
        },
        ...mapActions('updates', {
            getUpdates: 'getUpdates'
        }),
        mapUpdate(update) {
            return {
                url: update.slug ? '/actueel/' + update.slug : null,
                image: update.thumbnail && update.thumbnail.url ? update.thumbnail.url : null,
                title: update.title,
                date: update.postDate
            };
        }
    }
};
</script>
