import Vue from 'vue';
import Router from 'vue-router';
import { parse, stringify } from 'qs';

import Home from '../../../_patterns/organisms/home/home.vue';
import FourOhFour from '../../../_patterns/organisms/404/404.vue';
import Initiatives from '../../../_patterns/organisms/initiatives/initiatives.vue';
import UpdateContentPage from '../../../_patterns/organisms/update-content-page/update-content-page.vue';
import InitiativeContentPage from '../../../_patterns/organisms/initiative-content-page/initiative-content-page.vue';
import ContentPage from '../../../_patterns/organisms/content-page/content-page.vue';
import Tool from '../../../_patterns/organisms/tool/tool.vue';
import DuurzameWegwijzer from '~/_patterns/organisms/duurzame-wegwijzer/duurzame-wegwijzer-page.vue';

Vue.use(Router);

/*
 * WARNING: Make sure to also update `craft/config/routes.php` when needed, the server needs to know
 * which routes exist or else it can't throw 404 codes or serve the SPA correctly.
 */
export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/404',
            name: '404',
            component: FourOhFour
        },
        {
            path: '/',
            name: 'Nieuw Amsterdams Klimaat',
            component: Home
        },
        {
            path: '/initiatieven',
            name: 'Initiatieven',
            component: Initiatives
        },
        {
            path: '/initiatieven/:slug',
            name: 'Initiatief',
            component: InitiativeContentPage
        },
        {
            path: '/actueel/:slug',
            name: 'Updates',
            component: UpdateContentPage
        },
        {
            path: '/wat-kan-jij-doen/:slug',
            name: 'tool',
            component: Tool
        },
        {
            path: '/duurzame-wegwijzer/:slug*',
            name: 'duurzame-wegwijzer',
            component: DuurzameWegwijzer,
            meta: {
                root: '/duurzame-wegwijzer'
            }
        },
        {
            path: '/:slug',
            name: 'contentPage',
            component: ContentPage
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        if (to.name !== 'contentPage') {
            this.options.setDocumentTitle(to.name);
        }

        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    setDocumentTitle(title) {
        document.title = title;
    },
    // set custom query resolver (used for AIS routing)
    parseQuery: query => parse(query),
    stringifyQuery(query) {
        const result = stringify(query);
        return result ? `?${result}` : '';
    }
});
