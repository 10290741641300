<template>
    <div class="initiatives-collection">
        <h2>{{ title }}</h2>
        <p v-html="text" /> <!-- eslint-disable-line vue/no-v-html -->

        <div
            class="item-grid"
            data-columns-xs="1"
            data-columns-sm="1"
            data-columns-md="2"
            data-columns-lg="2"
            data-columns-xl="2"
        >
            <template v-for="(initiative, key) in initiativesCollection">
                <initiative-card :key="key" :initiative="initiative" />
            </template>
        </div>
    </div>
</template>

<script>
import { get } from 'lodash';
import InitiativeCard from '~/_patterns/atoms/initiative-card/initiative-card';

export default {
    components: {
        InitiativeCard
    },
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {
                    title: '',
                    text: '',
                    initiativesCollection: []
                };
            }
        }
    },
    computed: {
        title() {
            return get(this, 'content.title', '');
        },
        text() {
            return get(this, 'content.text', '');
        },
        initiativesCollection() {
            return get(this, 'content.initiativesCollection', []);
        }
    }
};
</script>
