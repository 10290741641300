<template>
    <div class="save-results background--grey background--bleed component">
        <h2>Bewaar jouw overzicht</h2>

        <p><click-to-copy copy-label="Kopieer de link" share-label="Deel de link" :copy="pageUrl" /> of mail de link naar jezelf of naar anderen.</p>

        <form ref="form" class="save-results__form" @submit.prevent="submitForm">
            <input v-model="email" type="email" placeholder="Emailadres" required autocomplete="email">
            <button type="submit" class="btn btn--primary">Mail jouw overzicht</button>
        </form>
    </div>
</template>

<script>
'use strict';

import { get } from 'lodash';

import query from '#/graphql';
import sendPersonalisedEmail from '#/graphql/mutations/sendPersonalisedEmail.graphql';
import ClickToCopy from '~/_patterns/atoms/click-to-copy/click-to-copy.vue';

export default {
    components: {
        ClickToCopy
    },
    props: {
        path: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            email: ''
        };
    },
    computed: {
        pageUrl() {
            return `${window.location.href}?utm_source=duurzame%20wegwijzer&utm_medium=direct%20link&utm_campaign=persoonlijke%20overzicht%20duurzame%20stappen`;
        }
    },
    methods: {
        async submitForm() {
            const result = await query.call(this, sendPersonalisedEmail, { email: this.email, path: this.path }),
                success = get(result, 'data.sendPersonalisedOverview', false);

            if (success) {
                this.$toast.success('Mail verzonden');
                this.$refs.form.reset();
            } else {
                this.$toast.error('Er ging iets fout');
            }
        }
    }
};
</script>
