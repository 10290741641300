<template>
    <div class="updates_content_page">
        <div v-if="loaded && update" class="update_single">
            <div class="container">
                <div class="row">
                    <div class="component">
                        <div class="updates_content_page__header background background--green background--bleed">
                            <div class="section">
                                <h2>{{ update.title }}</h2>
                            </div>
                        </div>

                        <div class="section">
                            <div class="update__image_single">
                                <img :src="update.image.url" :loading="loading">
                                <div class="update__date">
                                    {{ datum }}
                                </div>
                            </div>

                            <div class="update__grid">
                                <div class="update__cell update__cell1 update__subtitle">
                                    {{ update.subTitle }}
                                </div>
                                <div v-if="update.vimeoId" class="update__cell update__cell2 update__vimeo">
                                    <Vimeo :id="update.vimeoId" />
                                </div>
                                <div class="update__cell update__cell3 update__longtext" v-html="update.contentLong" /> <!-- eslint-disable-line vue/no-v-html -->
                            </div>
                        </div>
                    </div>

                    <initiatives-call-to-action v-if="update.callToAction" :content="update.callToAction" class="component" />
                </div>
            </div>

            <div class="more_updates_content_page" :class="updatesClasses">
                <div class="container">
                    <div class="row">
                        <div class="component">
                            <updates title="Meer actueel" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="loaded && !update">
            <div class="container">
                <div class="row">
                    <div class="hero">
                        <div class="section">
                            <div class="hero__content">
                                <h2 class="hero__title">
                                    404
                                </h2>

                                <p class="hero__lead lead">Deze pagina kan niet worden gevonden</p>

                                <call-to-action button-theme="blue" url="/">
                                    Ga naar de homepage
                                </call-to-action>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!loaded" class="container">
            <skeleton />
        </div>
        <SiteFooter />
    </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';

import { getMontName } from '#/utilities/utility';
import loading from '#/mixins/loading';

import SiteFooter from '~/_patterns/organisms/site-footer/site-footer.vue';
import Updates from '~/_patterns/organisms/updates/updates.vue';
import Vimeo from '~/_patterns/atoms/vimeo/vimeo.vue';
import CallToAction from '~/_patterns/atoms/call-to-action/call-to-action.vue';
import InitiativesCallToAction from '~/_patterns/molecules/initiative-cta/initiative-cta.vue';
import Skeleton from '~/_patterns/molecules/skeleton/skeleton';

export default {
    components: {
        Skeleton,
        SiteFooter,
        Updates,
        Vimeo,
        CallToAction,
        InitiativesCallToAction
    },
    mixins: [
        loading
    ],
    data() {
        return {
            loaded: false
        };
    },
    computed: {
        update() {
            return this.getBySlug(this.routeParams.slug)[0];
        },
        datum() {
            const myDate = new Date(this.update.postDate);
            return myDate.getDate() + ' ' + getMontName(myDate) + ' ' + myDate.getFullYear();
        },
        updatesClasses() {
            return this.update.callToAction ? '' : 'background background--grey';
        },
        ...mapGetters('updates', {
            getBySlug: 'getBySlug'
        }),
        ...mapState('route', {
            routeParams: 'params'
        })
    },
    created() {
        this
            .getUpdates()
            .catch(({ response }) => {
                if (response.status === 404) {
                    this.$router.replace({ name: '404' });
                }
            }).finally(() => {
                this.loaded = true;
            });
    },
    methods: {
        ...mapActions('updates', {
            getUpdates: 'getUpdates'
        })
    }
};
</script>
