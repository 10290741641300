<template>
    <div>
        <label class="form-label">{{ field.label }}&nbsp;</label>
        <div v-if="field.instructions" class="form-field-instructions">{{ field.instructions }}</div>
        <input :name="field.handle" type="text" :placeholder="field.placeholder" :value="field.value">
    </div>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true
        }
    }
};
</script>
