import { get, isUndefined } from 'lodash';
import { mapState } from 'vuex';

export default {
    computed: {
        path() {
            if (isUndefined(this.params.slug)) {
                return '';
            }

            return this.params.slug.replace(/\//g, '.paths.');
        },
        next() {
            return get(this.matrix, `${this.path}.paths`);
        },
        ...mapState('route', ['params'])
    }
};
