<template>
    <transition name="modal-popup">
        <div class="modal-popup__mask">
            <div class="modal-popup__wrapper">
                <div v-on-clickaway="away" class="modal-popup__container">
                    <div class="modal-popup__header">
                        <div class="modal-popup__close-icon" @click="close" />
                        <slot name="header" />
                    </div>
                    <div class="modal-popup__body">
                        <slot />
                    </div>
                    <div class="modal-popup__footer">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapActions } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';

export default {
    mixins: [clickaway],
    mounted() {
        this.activate();
    },
    destroyed() {
        this.deactivate();
    },
    methods: {
        close() {
            this.$emit('close');
        },
        away() {
            this.$emit('close');
        },
        ...mapActions('modal', {
            activate: 'activate',
            deactivate: 'deactivate'
        })
    }
};
</script>
