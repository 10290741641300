<template>
    <div>
        <advice-overview-header
            v-if="entry"
            class="component"
            :result-count="resultCount"
            :image="image"
            :text="text"
        />

        <template v-if="entry">
            <flexible-section v-if="entry" :sections="flexibleSection" />
        </template>

        <template v-if="!entry">
            <skeleton />
        </template>
    </div>
</template>

<script>

import { mapGetters, mapActions, mapState } from 'vuex';
import { get } from 'lodash';

import FlexibleSection, { adviceGroupBlockType, filterBlocks } from '~/_patterns/organisms/flexible-section/flexible-section.vue';
import AdviceOverviewHeader from '~/_patterns/molecules/advice-overview-header/advice-overview-header.vue';
import Skeleton from '~/_patterns/molecules/skeleton/skeleton';

export default {
    components: {
        Skeleton,
        FlexibleSection,
        AdviceOverviewHeader
    },
    computed: {
        entry() {
            const content = this.bySlug(this.params.slug)[0];

            if (typeof content === 'object' && content.title) {
                document.title = content.title;
                this.$router.options.setDocumentTitle(content.title);
            }

            return content;
        },
        image() {
            return get(this, 'entry.image.0.url', '');
        },
        text() {
            return get(this, 'entry.text', '');
        },
        flexibleSection() {
            return get(this, 'entry.flexibleSection', []);
        },
        adviceGroupBocks() {
            return this
                .flexibleSection
                .filter(filterBlocks(adviceGroupBlockType));
        },
        resultCount() {
            return this
                .adviceGroupBocks
                .reduce((acc, group) => {
                    const length = get(group, 'adviseList.length', 0);
                    return acc + length;
                }, 0);
        },
        ...mapGetters('advicePages', {
            bySlug: 'getByCustomSlug'
        }),
        ...mapState('route', {
            params: 'params'
        })
    },
    async created() {
        const page = await this.getByCustomSlug({ slug: this.params.slug });

        if (page === null) {
            this.$emit('404');
        }
    },
    methods: {
        ...mapActions('advicePages', {
            getByCustomSlug: 'getByCustomSlug'
        })
    }
};
</script>
