<template>
    <div class="vue-form-fields">
        <div v-for="row in rows" :key="row.id" :class="'form__row form__grid form__grid'+row.fields.length">
            <div v-for="field in row.fields" :key="field.id" :class="'form__cell form__cell'+row.fields.length">
                <component :is="getFieldName(field.type)" :field="field" />
                <div v-if="errors[field.handle]" class="errors">
                    Dit veld is verplicht
                </div>
            </div>
        </div>
    </div>
</template>
<script>

import textField from '../../../_patterns/atoms/freeform/text.vue';
import emailField from '../../../_patterns/atoms/freeform/email.vue';
import textareaField from '../../../_patterns/atoms/freeform/textarea.vue';
import radiogroupField from '../../../_patterns/atoms/freeform/radio-group.vue';
import checkboxgroupField from '../../../_patterns/atoms/freeform/checkbox-group.vue';
import submitField from '../../../_patterns/atoms/freeform/submit.vue';
import checkboxField from '../../../_patterns/atoms/freeform/checkbox.vue';
import fileField from '../../../_patterns/atoms/freeform/file.vue';

export default {
    components: {
        textField,
        radiogroupField,
        checkboxgroupField,
        textareaField,
        emailField,
        submitField,
        checkboxField,
        fileField
    },
    props: {
        rows: {
            type: Array,
            default: () => { return []; }
        },
        errors: {
            type: Array,
            default: () => { return []; }
        }
    },
    methods: {
        getFieldName(fieldName) {
            return fieldName.replace('_', '') + 'Field';
        }
    }
};
</script>
